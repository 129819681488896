.vacancy-card-container {
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  border: 1px solid #b6b6b6;
  margin-bottom: 1rem;
}

.vacancy-card-container .vacancy-card-content {
  padding-right: 1rem;
}

.vacancy-card-container .vacancy-card-apply {
  min-width: 200px;
}

.vacancy-card-container .vacancy-card-apply .apply-button {
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
}

.vacancy-card-container .vacancy-card-apply .vacancy-location {
  margin-top: 10px !important;
  font-size: 1rem;
  text-align: center;
}

.vacancy-card-container .vacancy-card-content .vacancy-header {
  font-size: 1rem;
  font-weight: 500;
}

.vacancy-card-container .vacancy-card-content .vacancy-description {
  margin: 1rem 0 !important;
  font-size: 1rem;
  line-height: 2rem;
}

.vacancy-card-container
  .vacancy-card-content
  .vacancy-features
  .vacancy-features-list {
  display: flex;
}

.vacancy-card-container
  .vacancy-card-content
  .vacancy-features
  .vacancy-features-list
  div {
  display: flex;
  align-items: center;
  margin-right: 3rem;
  font-size: 1rem;
}

.vacancy-card-container
  .vacancy-card-content
  .vacancy-features
  .vacancy-features-list
  div
  span {
  margin-right: 10px;
  background-color: #000000;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
}

.btn-hidden {
  display: none !important;
}

#careersAppication .ant-form-item-label > label {
  width: 8rem;
  font-size: 0.8rem;
}

/* empty field error message */
#careersAppication .ant-form-item-explain-error {
  font-size: 0.8rem !important;
}
/* empty field error message */

#careersAppication .ant-btn:hover,
#careersAppication .ant-btn:focus {
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
}

/* form item */
@media screen and (max-width: 575px) {
  #careersAppication .ant-form-item {
    margin-bottom: 0.6rem !important;
  }

  #careersAppication .ant-form-item-label {
    padding: 0 !important;
  }
}
/* form item */

@media (max-width: 500px) {
  .vacancy-card-container {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  .vacancy-card-container .vacancy-card-apply {
    width: 120px;
    margin: 2rem auto 1rem auto;
  }
}
