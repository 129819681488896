.endOfLease {
  display: flex;
}

.endOfLease .endOfLease__containerLeft {
  margin-right: 2rem;
}

.endOfLease .endOfLease__containerRight {
  width: 800px;
}

@media screen and (max-width: 1000px) {
  .endOfLease .endOfLease__containerRight {
    width: 880px;
  }
}

@media screen and (max-width: 845px) {
  .endOfLease {
    flex-direction: column;
  }

  .endOfLease .endOfLease__containerLeft {
    margin-right: 0;
  }

  .endOfLease .endOfLease__containerRight {
    width: 100%;
  }
}
