.welcome_container {
  background: var(--primary-color);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.welcome_container h1 {
  font-style: normal;
  font-weight: bold;
  line-height: 77px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
}

.welcome_container p {
  font-style: normal;
  font-weight: normal;
  line-height: 30px;
}

.welcome_container > .welcome_content {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.welcome_container > .welcome_content1 {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.welcome_image{
  margin-left: -10px;
}
