.dashboardLanding .dashboardLanding__body > ul {
  list-style: none;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .dashboardLanding .dashboardLanding__body > ul {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
  }
}
