.card {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 45px;
}

.card_wrapper {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 422px;
  height: 388.05px;
  display: flex;
  flex-direction: column;
  margin-top: 45px;
}

.card_header {
  background: #c4c4c4;
  width: 422px;
  height: 328px;
}

.card_footer {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0 10px; */
  width: 100%;
  margin-bottom: 10px;
}

.service_card {
  margin: 0 auto !important;
}

@media (max-width: 420px) {
  .card_footer {
    height: 30.33px;
  }

  .ant-card {
    width: 340px !important;
  }
}

@media (max-width: 768px) {
  .ant-card {
    width: 320px !important;
    margin-left: 5px !important;
  }
}

@media (max-width: 320px) {
  .ant-card {
    width: 290px !important;
  }
}
