.notificationbody {
  display: flex;
  align-items: center;
  padding: 1rem;
  font-size: 0.9rem;
  /* color: blue; */
  margin-top: 0.5%;
}

.notificationbody:hover {
  /* background: blue; */
  color: blue;
  cursor: pointer;
}
.notificationicon:hover {
  color: blue;
  cursor: pointer;
}

.notificationbody .notificationicon {
  width: 15%;
}
.notificationbody .notificationitem {
  width: 100%;
}
.notificationbody .notificationactive {
  width: 10%;
  justify-content: flex-end;
}
.notificationactive {
}
