.about-us-cards-container {
  padding: 1rem;
  background-color: #f5f5f5;
}

.about-us-cards-container .header h3 {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
}

.about-us-cards-container .header h3 span {
  color: var(--primary-color);
  font-weight: 600;
}

.about-us-cards-container .about-us-cards-group {
  padding: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 2rem;
  row-gap: 2rem;
}

.about-us-cards-container .about-us-cards-group .about-us-card {
  min-width: 350px;
  text-align: center;
  max-width: 350px;
}

.about-us-cards-container .about-us-cards-group .about-us-card img {
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.about-us-cards-container .about-us-cards-group .about-us-card h5 {
  margin: 1rem auto !important;
  font-size: 1rem;
}

.about-us-cards-container .about-us-cards-group .about-us-card h5 span {
  color: var(--primary-color);
  font-weight: 600;
}

.about-us-cards-container
  .about-us-cards-group
  .about-us-card
  .about-us-card-description {
  color: #434343;
  font-size: 1rem;
  line-height: 1.75rem;
}

@media (max-width: 800px) {
  .about-us-cards-container .about-us-cards-group .about-us-card {
    min-width: 300px;
    text-align: center;
    max-width: 300px;
  }
}
