.uploadFileSites {
  padding: 1rem;
}

.uploadFileSites
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #0f8015;
  border-color: #0f8015;
}

.uploadFileSites
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #0f8015;
  border-color: #0f8015;
}

.uploadFileSites .ant-radio-group-solid :hover {
  color: #fff;
  background: #0f8015;
  border-color: #0f8015;
}

.uploadFileSites .uploadFileSites__upload,
.uploadFileSites .uploadFileSites__issueUpload {
  text-align: center;
}

.uploadFileSites .uploadFileSites__issueUpload {
  margin-bottom: 0.5rem;
}

.uploadFileSites .uploadFileSites__upload .uploadFileSites__icon,
.uploadFileSites .uploadFileSites__issueUpload .uploadFileSites__icon {
  height: 12rem;
  width: 12rem;
  margin: 1.5rem auto 0.8rem auto;
}

.uploadFileSites .uploadFileSites__upload .uploadFileSites__icon svg,
.uploadFileSites .uploadFileSites__issueUpload .uploadFileSites__icon svg {
  color: var(--primary-color);
  font-size: 3rem;
}

.uploadFileSites .uploadFileSites__upload .uploadFileSites__icon:hover,
.uploadFileSites .uploadFileSites__issueUpload .uploadFileSites__icon:hover {
  border: 2px solid var(--secondary-color);
}

.uploadFileSites .uploadFileSites__upload .uploadFileSites__uploadBtn button,
.uploadFileSites
  .uploadFileSites__issueUpload
  .uploadFileSites__uploadBtn
  button {
  width: 12rem;
  height: 2.5rem;
}

.uploadFileSites .uploadFileSites__query {
  display: flex;
  align-items: center;
}

.uploadFileSites
  .uploadFileSites__query
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--primary-color);
}

.uploadFileSites .uploadFileSites__footerBtns {
  text-align: end;
}

.uploadFileSites .uploadFileSites__footerBtns button {
  height: 3rem;
  width: 12rem;
}

.uploadFileSites .uploadFileSites__footerBtns button:last-child {
  margin-left: 1rem;
  background-color: #ff1919;
  color: #ffffff;
}

.uploadFileSites .uploadFileSites__footerBtns button:last-child:hover {
  background-color: #ffffff;
  color: #ff1919;
  border-color: #ff1919;
}

@media screen and (max-width: 480px) {
  .uploadFileSites .uploadFileSites__footerBtns {
    display: flex;
    flex-direction: column;
  }

  .uploadFileSites .uploadFileSites__footerBtns button:last-child {
    margin: 0.8rem 0 0 0;
  }

  .uploadFileSites .uploadFileSites__footerBtns button {
    width: 100%;
  }
}
