.App.mob-menu-open {
  overflow: hidden;
}

.App.mob-menu-open::after {
  background: rgba(0, 0, 0, 0.73);
  width: 100%;
  height: 100%;
  z-index: 1000;
  content: " ";
  position: fixed;
  display: block;
}
