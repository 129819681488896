.topClients {
  padding: 4rem 0;
}

.topClients .content .topClients__header {
  margin-bottom: 1rem;
}

.topClients .content .topClients__header h3 {
  text-align: center;
  font-size: 1.5rem;
}

.topClients .content .topClients__header h3 span {
  color: var(--primary-color);
}

.topClients .content .topClients__body {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.topClients .content .topClients__body .topClients__image {
  flex: 33.33%;
  max-width: 33.33%;
  text-align: center;
}

.topClients .content .topClients__body .topClients__image img {
  object-fit: contain;
  width: auto;
  height: auto;
  max-width: 200px;
  max-height: 150px;
}

@media screen and (max-width: 980px) {
  .topClients .content .topClients__body .topClients__image {
    flex: 50%;
    max-width: 50%;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 750px) {
  .topClients {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .topClients .content .topClients__body .topClients__image {
    flex: 100%;
    max-width: 100%;
  }
}
