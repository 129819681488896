.covidCleaner {
  display: flex;
  column-gap: 4rem;
}

.covidCleaner .covidCleaner__container p {
  padding-bottom: 1rem;
  font-size: 1rem;
  line-height: 2rem;
  text-align: justify;
}

.covidCleaner .covidCleaner__container .covidCleaner__header {
  margin: 1rem 0;
}

.covidCleaner .covidCleaner__container .covidCleaner__header h2 {
  color: var(--primary-color);
}

.covidCleaner .covidCleaner__container .covidCleaner__description ul {
  padding: 0 1rem;
}

.covidCleaner .covidCleaner__container .covidCleaner__description ul li {
  font-size: 1rem;
  line-height: 2rem;
}

.covidCleaner .covidCleaner__image {
  min-width: 400px;
  max-width: 400px;
}

.covidCleaner .covidCleaner__image img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 900px) {
  .covidCleaner {
    display: block;
  }

  .covidCleaner .covidCleaner__container {
    margin-bottom: 2rem;
  }

  .covidCleaner .covidCleaner__container p {
    padding: 0;
  }
}

@media screen and (max-width: 480px) {
  .covidCleaner .covidCleaner__image {
    min-width: 150px;
  }
}
