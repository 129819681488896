.generate-quote-container .generate-quote-image-container {
  margin: 1rem auto;
  text-align: center;
}

.generate-quote-container .description {
  font-size: 1rem;
  text-align: center;
  color: #434343;
}

.generate-quote-container .generate-quote-form-container {
  margin: 1rem 0;
}

.generate-quote-container .generate-quote-form-container .generate-quote-form {
  width: 100%;
  margin: 0 auto;
}

.generate-quote-form-container
  .generate-quote-form
  .get-quote-button-container {
  text-align: center;
}

.generate-quote-form-container
  .generate-quote-form
  .get-quote-button-container
  .get-quote-button {
  padding: 8px 50px;
  height: 38px;
}

@media (min-width: 600px) {
  .generate-quote-container .generate-quote-form-container .generate-quote-form {
    width: 60%
  }
}