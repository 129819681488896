.document .document__body {
  background-color: #ffffff;
  border-radius: 5px;
}

.document .document__body .document__bodyHead {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid var(--light-color);
  padding: 1rem;
  font-weight: 500;
}

.document .document__body .document__bodyHead p:first-child {
  width: 45%;
}
.document .document__body .document__bodyHead p:nth-child(2) {
  width: 25%;
}
.document .document__body .document__bodyHead p:nth-child(3) {
  width: 30%;
  text-align: end;
}

.document .document__body ul li {
  list-style: none;
  border-bottom: 1px solid var(--light-color);
}

.document .document__body ul li:last-child {
  border-bottom: none;
}

.document .document__footer {
  display: flex;
  align-items: center;
  margin-top: 1.4rem;
}

.document .document__footer > p {
  padding-left: 0.4rem;
  font-size: 0.8rem;
}

@media screen and (max-width: 620px) {
  .document .document__body .document__bodyHead {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.9rem;
  }
}
