.custom-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
}

.custom-loader .ant-spin-dot-item {
  background-color: var(--color-primary);
}

.custom-loader .ant-spin-lg .ant-spin-dot {
  font-size: 50px;
}

.custom-loader .ant-spin-lg .ant-spin-dot i {
  width: 20px;
  height: 20px;
}
