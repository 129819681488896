/* .EOL_Content{
    display: flex;
    gap: 20px;
} */

.EndOfLeasePrimary_container {
  padding: 1.2rem 0;
}

.EndOfLeasePrimary_container h2 {
  margin-bottom: 1rem !important;
  color: var(--primary-color);
}

.EndOfLeasePrimary_container .EndOfLeasePrimary_content .ant-radio-group,
.ant-space {
  width: 100%;
}

.EndOfLeasePrimary_container
  .EndOfLeasePrimary_content
  .ant-radio-group
  .ant-space
  .radioUnit_conatainer
  .radioUnit_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}

.EndOfLeasePrimary_container
  .EndOfLeasePrimary_content
  .ant-radio-group
  .ant-space
  .radioUnit_conatainer
  .radioUnit_item
  h4,
span.ant-radio + * {
  font-size: 1rem;
}

.EndOfLeasePrimary_container
  .EndOfLeasePrimary_content
  .ant-radio-group
  .ant-space
  .radioUnit_conatainer
  .radioUnit_item
  span {
  font-size: 0.95rem;
}

.EndOfLeasePrimary_container
  .EndOfLeasePrimary_content
  .ant-radio-group
  .ant-space
  .radioUnit_conatainer
  .radioUnit_item
  .ant-btn {
  margin: 0 20px;
}

hr {
  width: 100%;
}

.radio_option {
  border-bottom: 1px solid var(--light-color);
  padding: 1.2rem 0;
}
