/* .formLabel {
  margin: 0;
} */

.formLabel .ant-col-24.ant-form-item-label {
  padding: 0 !important;
  padding-top: 0rem !important;
}

.formLabel .ant-col-24.ant-form-item-label > label {
  font-size: 0.8rem;
}
