@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

:root {
  --primary-color: #0f8015;
  --secondary-color: #74bc7c;
  --dark-color: #2d2d2d;
  --light-color: #d8d8d8;
  --bg-color: #2d2d2d;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 !important;
}

a {
  text-decoration: none !important;
  color: var(--primary-color) !important;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

button {
  border-radius: 5px !important;
}

button.bg-dark {
  color: #ffffff !important;
  background-color: var(--primary-color) !important;
  border: none;
}

button.bg-dark:disabled {
  pointer-events: none;
  background-color: var(--secondary-color) !important;
}

button.bg-dark:hover {
  color: var(--primary-color) !important;
  background-color: #ffffff !important;
  border: 1px solid var(--primary-color) !important;
}

button.bg-light {
  color: var(--primary-color) !important;
  background-color: #ffffff !important;
  border: 1px solid var(--primary-color);
}

button.bg-light:hover {
  color: #ffffff !important;
  background-color: var(--primary-color) !important;
  border: none;
}

button .ant-spin {
  margin-left: 1rem;
}

button .ant-spin-dot-item {
  background-color: #ffffff;
}

button:hover .ant-spin-dot-item {
  background-color: var(--primary-color);
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 2px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4d70c7e7;
}

.ant-popover .ant-popover-buttons .ant-btn.ant-btn-primary {
  background-color: #ff4d4f;
  border: #ff4d4f;
}

.ant-radio .ant-radio-inner {
  border-radius: 0;
  border: 1px solid var(--primary-color);
  height: 1.2rem;
  width: 1.2rem;
  position: relative;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: var(--primary-color);
  border-radius: 0;
  height: 0.8rem;
  width: 0.8rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
