.forgotPassword .forgotPassword__header {
  border-bottom: 2px solid var(--light-color);
  padding: 0.5rem 2rem;
}

.forgotPassword .forgotPassword__header img {
  width: 90px;
}

.forgotPassword .forgotPassword__container {
  width: 90%;
  margin: 0 auto;
}

.forgotPassword .forgotPassword__body {
  max-width: 450px;
  margin: 0 auto;
  margin-top: 2.5rem;
  text-align: center;
  border: 1px solid var(--light-color);
  padding: 2rem;
  padding-top: 3rem;
  border-radius: 30px;
}

.forgotPassword .forgotPassword__body svg {
  color: #00bc12;
  font-size: 5rem;
}

.forgotPassword .forgotPassword__body h2 {
  padding: 2rem 0 1rem 0;
}

.forgotPassword .forgotPassword__body p {
  padding-bottom: 1rem;
}
