.whyChoose {
  padding: 4rem;
  background-color: #f5f5f5;
}

.whyChoose-isWhite {
  background-color: #ffffff;
}

.whyChoose .whyChoose__header {
  margin-bottom: 1rem;
}

.whyChoose .whyChoose__header h3 {
  text-align: center;
  font-size: 1.5rem;
}

.whyChoose .whyChoose__subtitle p {
  text-align: center;
  font-size: 1rem;
  line-height: 2rem;
  color: #434343;
}

.whyChoose .whyChoose__body {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.whyChoose .whyChoose__body .whyChoose__image {
  width: 65%;
}

.whyChoose .whyChoose__body .whyChoose__image img {
  width: 100%;
  height: auto;
}

@media (max-width: 1200px) {
  .whyChoose .whyChoose__header {
    margin-bottom: 1.4rem;
  }

  .whyChoose .whyChoose__body .whyChoose__image {
    width: 70%;
  }

  .whyChoose .whyChoose__subtitle p {
    font-size: 1rem;
  }
}

@media (max-width: 1180px) {
  .whyChoose {
    padding: 4rem 2rem;
  }
}

@media (max-width: 1000px) {
  .whyChoose .whyChoose__subtitle p {
    text-align: justify;
  }

  .whyChoose .whyChoose__subtitle p {
    line-height: 1.8rem;
  }
}

@media (max-width: 750px) {
  .whyChoose {
    padding: 2rem 1rem;
  }

  .whyChoose .whyChoose__body .whyChoose__image {
    width: 100%;
  }
}
