.qoute_container {
  padding: 2rem 2rem 3rem;
  background: #f5f5f5;
  margin: 40px 0 0 0;
}

.heading_style {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
}

.heading_style > span {
  font-weight: 700;
  line-height: 35px;
  color: #0f8015;
}

.qoute_container .qoute_col {
  text-align: center;
  padding: 0 50px;
}

.qoute_col:not(:last-child) {
  border-right: 1px solid #b6b6b6;
}

.qoute_header {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1rem;
  color: #212121;
}

.qoute_details {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 20px;
  color: #212121;
}

.content > h1 {
  color: #0f8015;
}

.button_container {
  text-align: center;
  margin-top: 20px;
}

.button_container > p {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 20px;
  color: #0f8015;
}

.button_container button {
  font-weight: 500;
}

.services-quote.ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}

.services-quote .ant-tabs-tab-btn {
  font-size: 1rem;
  color: #000000;
}

.services-quote .ant-tabs-tab-btn:hover {
  color: var(--primary-color);
}

.services-quote .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary-color);
}

.services-quote .ant-tabs-ink-bar {
  background: none;
}

@media (max-width: 1040px) {
  .qoute_col:not(:last-child) {
    border-right: none;
  }

  .qoute_col:nth-child(2n + 1) {
    border-right: 1px solid #b6b6b6;
    padding-bottom: 20px;
  }
}

@media (max-width: 750px) {
  .qoute_col:not(:last-child) {
    border-right: none;
  }

  /* .qoute_col:nth-child(2n + 1) {
    border-right: 1px solid #b6b6b6;
    padding-bottom: 20px;
  } */
}

@media screen and (max-width: 500px) {
  .qoute_col:not(:last-child) {
    border-right: none;
    padding-bottom: 20px;
  }

  .qoute_col:nth-child(2n + 1) {
    border-right: none;
    padding-bottom: 20px;
  }
}
