.contact-container {
  padding: 2rem 0;
}

.contact-container .contact-header {
  text-align: center;
}

.contact-container .contact-header p {
  font-size: 14px;
  font-weight: 500;
}

.contact-container .contact-header h1 span {
  color: var(--primary-color);
}

.contact-container .contact-form-container {
  display: flex;
  justify-content: center;
  column-gap: 100px;
  margin: 2rem 0;
}

.contact-container .contact-form-container > div {
  flex-basis: 40%;
}

.contact-container .contact-form-container .contact-form form {
  border: 1px solid #b6b6b6;
  padding: 2rem 1rem;
}

.contact-container .contact-form-container .contact-form form .submit-button {
  width: auto;
  height: auto;
  padding: 8px 40px;
  margin-top: 1rem;
  font-size: 1rem;
}

.contact-form form input,
.contact-form form input:hover,
.contact-form form textarea,
.contact-form form textarea :hover {
  border: none;
  border-bottom: 1.5px solid #000000 !important;
}

.contact-form form input:focus,
.contact-form form textarea:focus {
  border: none;
  border-bottom: 1.5px solid var(--primary-color) !important;
  box-shadow: none;
}

.contact-container .contact-info {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
}

.contact-container .contact-info .info-with-icon {
  display: flex;
  align-items: center;
}

.contact-container .contact-info .info-with-icon a {
  display: block;
  color: #000000 !important;
}

.contact-container .contact-info .info-with-icon svg {
  margin-right: 10px;
  font-size: 1rem;
}

.contact-container .contact-info .info-with-icon:first-child svg {
  margin-top: -14px;
}

.contact-container .contact-info .contact-map {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: 450px;
}

@media (max-width: 750px) {
  .contact-container .contact-form-container {
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    margin: 2rem 0;
    padding: 0 2rem;
  }
}

@media (max-width: 500px) {
  .contact-container .contact-form-container {
    padding: 0 1rem;
  }
}
