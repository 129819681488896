.extrasItem {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin: 0.8rem;
}

.extrasItem .extrasItem__icon {
  font-size: 3.4rem;
  color: var(--primary-color);
}
