.covidService {
  background-color: #329137;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.covidService > p {
  padding-bottom: 1rem;
  font-weight: 700;
  font-size: 1rem;
}

.covidService .covidService__contacts {
  display: flex;
  width: 30%;
  justify-content: center;
}

.covidService .covidService__contacts .covidService__contactItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.covidService .covidService__contactItem h2 a {
  color: #ffffff !important;
}

@media screen and (min-width: 1300px) {
  .covidService .covidService__contacts {
    width: 25%;
  }
}

@media screen and (max-width: 1300px) {
  .covidService .covidService__contacts {
    width: 40%;
  }
}

@media screen and (max-width: 800px) {
  .covidService .covidService__contacts {
    width: 60%;
  }
}

@media screen and (max-width: 550px) {
  .covidService .covidService__contacts {
    width: 90%;
  }
}

@media screen and (max-width: 420px) {
  .covidService .covidService__contactItem h2 {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 340px) {
  .covidService > p {
    font-size: 0.9rem;
  }

  .covidService .covidService__contacts {
    width: 100%;
  }

  .covidService .covidService__contactItem h2 {
    font-size: 1rem;
  }
}
