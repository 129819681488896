.service .service__body {
  background-color: #ffffff;
  padding: 1rem 1.4rem;
  border-radius: 5px;
  font-size: 0.9rem;
}

.service .service__body > p {
  line-height: 2rem;
  text-align: justify;
}

.service .service__body > p:nth-child(2) {
  padding: 1rem 0 0.6rem 0;
}

.service .service__body ul li {
  line-height: 1.8rem;
  list-style-position: inside;
}
