.service_container {
  padding: 50px 2rem;
}


@media (max-width: 768px) {
  .service_container {
    text-align: -webkit-right;
  }
  .Service_category {
    display: none;
  }
  .service_card_col {
    margin: 45px 0 0 -16px;
  }

  /* .service_container .ant-col {
    margin: 0 auto;
  } */
}

@media (max-width: 420px) {
  .Service_category {
    display: none;
  }

  .service_card_col {
    margin: 45px 0 0 -16px;
  }
}
