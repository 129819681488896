.services {
  padding: 2rem;
}

.services .content .services__header {
  margin-bottom: 1rem;
}

.services .content .services__header h3 {
  text-align: center;
  font-size: 1.5rem;
}

.services .content .services__header h3 span {
  color: var(--primary-color);
}

.services .content .services__body .services__bodyItem ul {
  column-count: 3;
  column-width: 320px;
  list-style: inside;
  padding: 0;
}

.services .content .services__body .services__bodyItem ul li {
  list-style-type: none;
  break-inside: avoid;
}

.services .content .services__body .services__bodyItem ul li a {
  color: #212121 !important;
}

@media (max-width: 465px) {
  .services {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
