.page-header-container {
  padding: 1.5rem 2rem;
  display: flex;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.page-header-container .overlay {
  position: absolute;
  width: 55%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    89.24deg,
    rgba(255, 255, 255, 0.78) 79.28%,
    rgba(196, 196, 196, 0) 108.45%
  );
  opacity: 50%;
  border-right: 50px;
}

.page-header-container .header-content {
  z-index: 1;
}

.page-header-container .header {
  color: var(--primary-color);
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
}

.page-header-container .breadcrumb {
  font-size: 1rem;
  /* font-weight: 500; */
}

.page-header-container .breadcrumb a {
  color: #000000 !important;
}

.page-header-container .breadcrumb a::after {
  content: "|";
  margin: 0 10px;
}

.page-header-container .header_text {
  width: 55%;
  padding: 10px 20px 10px 0;
  text-align: justify;
}

.page-header-container .header_button h3 {
  margin-top: 10px !important;
  font-weight: 600;
  color: var(--primary-color);
}

.page-header-container .header_button .call_btn {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.page-header-container .header_button .call_btn svg {
  margin-right: 5px;
}

@media (max-width: 745px) {
  .page-header-container .header {
    font-size: 1rem;
    font-weight: 400;
  }

  .page-header-container .header_text {
    width: 100%;
    padding: 10px 20px 10px 0;
    text-align: justify;
  }
}
