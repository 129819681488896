.subpageSites .subpageSites__body {
  background-color: #ffffff;
  border: 5px;
}

.subpageSites .subpageSites__body > h4 {
  border-bottom: 1px solid var(--light-color);
  line-height: 3.5rem;
  padding: 0 1rem;
}
