.covid {
  padding: 2rem 1rem;
}

.covid .content .covid__header h1 {
  text-align: center;
}

.covid .content .covid__header h1 span {
  color: var(--primary-color);
}

.covid .content .covid__subtitle {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.covid .content .covid__subtitle p {
  font-size: 1rem;
  line-height: 2rem;
  text-align: justify;
}
