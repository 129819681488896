.service_detail_container {
  padding: 2rem 0 0 0;
}

.service_detail_container .service_detail_content_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 30px;
  margin: 2rem 0;
}

.service_detail_container
  .service_detail_content_container
  .service_detail_text_carousel_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 80px;
}

.service_detail_container
  .service_detail_content_container
  .service_detail_text_carousel_container
  .service_detail_text
  p {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-align: justify;
  color: #434343;
}

.service_detail_container
  .service_detail_content_container
  .service_detail_text_carousel_container
  .service_detail_text
  p {
  text-align: justify;
}

.service_detail_container
  .service_detail_content_container
  .service_detail_category_container {
  display: flex;
  justify-content: center;
}

/* customer_offer_container_heading */
.customer_offer_container {
  margin-top: 4rem;
}

.customer_offer_container .customer_offer_container_heading h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-transform: uppercase;
  color: #0f8015;
}

.customer_offer_container .customer_offer_container_content p {
  padding-top: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #434343;
  text-align: justify;
}

.customer_offer_container .customer_offer_container_content ul {
  margin: 20px 0 0 18px;
}

.customer_offer_container .customer_offer_container_content ul li {
  /* padding-top: 20px; */
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  /* line-height: 10px; */
  color: #434343;
  text-align: justify;
}

@media (max-width: 750px) {
  .service_category {
    min-width: 22rem;
  }

  .service_category_body ul li {
    font-size: 14px;
  }
  .service_detail_container .service_detail_content_container {
    display: flex;
    flex-direction: column;
    row-gap: 60px;
    margin: 2rem 0;
  }
}

.service_detail_img_gallery {
  width: 100%;
  background: #f5f5f5;
  padding: 30px 50px;
  position: relative;
}

.image-gallery-image {
  height: 400px;
  object-fit: cover !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 1.5rem !important;
  width: 1.5rem !important;
  border-radius: 50%;
  background: var(--primary-color);
}

.image-gallery-left-nav {
  left: -7.5% !important;
}

.image-gallery-right-nav {
  right: -7.5% !important;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

@media (min-width: 750px) {
  .service_category {
    min-width: 24rem;
  }
}

@media (max-width: 500px) {
  .service_category {
    min-width: 18rem;
  }
}
