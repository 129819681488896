.hero {
  background-image: url("../../../assets/hero_image.png");
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: cover;
  width: 100%;
  margin-bottom: 2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
}

.hero .hero__container {
  width: 50%;
  height: 100%;
  padding: 4rem 2rem 4rem 3rem;
  background: linear-gradient(
    89.23deg,
    rgba(255, 255, 255, 0.78) 80.29%,
    rgba(196, 196, 196, 0) 109.82%
  );
}

.hero .hero__container .hero__header {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.hero .hero__container .hero__header h1 {
  font-weight: 700;
}

.hero .hero__container .hero__header > h1 > span {
  color: var(--primary-color);
}

.hero .hero__container .hero__body {
  text-align: justify;
  line-height: 2rem;
  font-size: 1.1rem;
  margin-bottom: 2rem;
}

.hero .hero__container .hero__bottom {
  display: flex;
}

.hero .hero__container .hero__bottom .ant-btn .hero_call_button {
  color: aliceblue;
}

.hero .hero__container .hero__bottom button {
  height: 3.2rem;
  font-size: 1rem;
}

.hero .hero__container .hero__bottom .hero__btnRequest {
  width: 14rem;
  margin-right: 2rem;
}

.hero .hero__container .hero__bottom .hero__btnCall {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 9rem;
}

.hero .hero__container .hero__bottom .hero__btnCall .hero__btnIcon {
  margin-right: 0.6rem;
}

.hero .hero__container .hero__bottom .hero__btnCall:hover {
  color: #ffffff !important;
}

.hero .hero__container .hero__bottom .hero__btnCall .hero__btnInfo {
  text-align: start;
}

.hero
  .hero__container
  .hero__bottom
  .hero__btnCall
  .hero__btnInfo
  p:first-child {
  font-weight: 700;
}

.hero
  .hero__container
  .hero__bottom
  .hero__btnCall
  .hero__btnInfo
  p:last-child {
  font-size: 0.8rem;
}

.hero
  .hero__container
  .hero__bottom
  .hero__btnCall
  .hero__btnInfo
  p:last-child
  span {
  font-weight: 700;
}

@media (max-width: 1425px) {
  .hero .hero__container .hero__header h1 {
    font-size: 1.5rem;
  }
}

@media (max-width: 1000px) {
  .hero .hero__container {
    width: 100%;
  }

  .hero .hero__container .hero__header h1 {
    font-size: 1.8rem;
  }

  .hero .hero__container .hero__body {
    font-size: 1rem;
    line-height: 1.8rem;
  }
}

@media (max-width: 750px) {
  .hero {
    background-position: 80% 0;
  }

  .hero .hero__container {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .hero .hero__container .hero__header {
    font-size: 1rem;
  }
}

@media (max-width: 500px) {
  .hero .hero__container .hero__bottom {
    flex-direction: column;
    align-items: center;
  }

  .hero .hero__container .hero__bottom button {
    width: 100% !important;
  }

  .hero .hero__container .hero__bottom .hero__btnRequest {
    margin-bottom: 1.2rem;
    margin-right: 0;
  }
}

@media (max-width: 465px) {
  .hero .hero__container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
