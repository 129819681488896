.viewdata {
    display: flex;
    align-items: center;
    padding: 1rem;
    font-size: 0.9rem;
  }
  
  .viewdata .viewdata__name {
    width: 45%;
  }
  
  .viewdata .viewdata__options {
    display: flex;
    justify-content: flex-end;
    width: 60%;
  }
  
  .viewdata .viewdata__options .viewdata__btn button {
    width: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .viewdata .viewdata__options .viewdata__btn:first-child {
    margin-right: 0.4rem;
  }
  
  @media screen and (max-width: 620px) {
    .viewdata {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      font-size: 0.8rem;
    }
  }
  