.uploadFile {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1.5rem 1rem;
}

.uploadFile .uploadFile__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2.5rem;
}

.uploadFile .uploadFile__body .uploadFile__icon {
  font-size: 3rem;
  height: 12rem;
  width: 12rem;
  cursor: pointer;
}

.uploadFile .uploadFile__body .uploadFile__icon svg {
  color: var(--primary-color);
  font-size: 3rem;
}

.uploadFile .uploadFile__body .uploadFile__icon:hover {
  border: 2px solid var(--secondary-color);
}

.uploadFile .uploadFile__btn {
  text-align: center;
}

.uploadFile .uploadFile__btn > button {
  height: 2.2rem;
  width: 12rem;
}

/* .ant-upload.ant-upload-select {
  display: flex !important;
  justify-content: center !important;
} */

.ant-upload-list-text .ant-upload-list-item-name {
  color: var(--primary-color) !important;
}

/* .ant-upload-list-item-info svg */
