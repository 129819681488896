.messageEditor {
  background-color: #ffffff;
  padding: 1.5rem 1rem;
  border-radius: 5px;
}

.messageEditor .messageEditor__editor {
  border: 1px solid var(--light-color);
  padding: 2px;
  min-height: 400px;
  margin: 1.5rem 0;
  padding: 0.5rem;
}

.messageEditor .messageEditor__btns {
  text-align: end;
}

.messageEditor .messageEditor__btns > button {
  width: 9rem;
  height: 2.3rem;
}

.messageEditor .messageEditor__btns > button:first-child {
  margin-right: 0.8rem;
}

@media screen and (max-width: 420px) {
  .messageEditor .messageEditor__btns {
    display: flex;
    flex-direction: column-reverse;
  }

  .messageEditor .messageEditor__btns > button {
    width: 100%;
  }

  .messageEditor .messageEditor__btns > button:first-child {
    margin-right: 0;
    margin-top: 0.8rem;
  }
}
