.bookingSummary {
  border: 1px solid var(--light-color);
  padding: 1.6rem;
  padding-bottom: 0.4rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  margin: 1rem 0;
}

.bookingSummary .bookingSummary__caption {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 2px solid var(--light-color);
}

.bookingSummary .bookingSummary__caption > span:first-child {
  font-weight: 700;
}

.bookingSummary .bookingSummary__caption > span:last-child {
  text-transform: uppercase;
}

.bookingSummary .bookingSummary__total {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.bookingSummary .bookingSummary__total > p {
  font-weight: 700;
}

.bookingSummary .bookingSummary__total > span {
  font-size: 2rem;
  color: var(--primary-color);
}
