.footer-container {
  margin-top: 1rem;
  background: var(--dark-color);
  color: #ffffff;
  padding: 2rem;
}

.footer-container .logo {
  width: 150px;
  margin-bottom: 1rem;
}

.footer-container .footer-items-container {
  display: flex;
  justify-content: center;
}

.footer-container .footer-items-container .footer-item {
  min-width: 300px;
}

.footer-container .footer-items-container .footer-item .footer-item-header {
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
}

.footer-container .footer-items-container .footer-item .footer-logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-container .footer-items-container .footer-item .quick-links {
  list-style: none;
  padding-left: 0;
  margin: 1rem 0;
}

.footer-container
  .footer-items-container
  .footer-item
  .quick-links
  .quick-link-item
  a {
  font-size: 1rem;
  line-height: 2rem;
  color: #ffffff !important;
}

.footer-container .services-list-container {
  display: flex;
}

.footer-container .services-list-container .services-list {
  list-style: none;
  columns: 2;
  column-gap: 80px;
  padding-left: 0;
  margin: 1rem 0;
  column-rule: 1px solid #ffffff;
}

.footer-container .services-list-container .services-list a {
  font-size: 1rem;
  line-height: 2rem;
  color: #ffffff !important;
}

.footer-container .footer-items-container .footer-item .services-list a svg {
  margin-right: 10px;
}

.footer-copyright-container {
  background: var(--primary-color);
  color: #ffffff;
}

.footer-copyright-container .copyright {
  text-align: center;
  padding: 5px 0;
  font-size: 1rem;
}

@media (max-width: 1200px) {
  .footer-container .footer-items-container .footer-item {
    min-width: 200px;
  }
}

@media (max-width: 920px) {
  .footer-container .footer-items-container {
    display: flex;
    flex-wrap: wrap;
  }

  .footer-container .footer-items-container .footer-item {
    flex-basis: 50%;
  }
  .footer-container .footer-items-container .footer-services-item {
    flex-basis: 100%;
  }
}

@media (max-width: 520px) {
  .footer-container {
    padding: 1rem;
  }

  .footer-container .footer-items-container {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
  }

  .footer-container .footer-items-container .footer-item {
    flex-basis: 50%;
    min-width: 150px;
  }

  .footer-container .footer-items-container .footer-services-item {
    flex-basis: 100%;
  }

  .footer-container .services-list-container .services-list {
    columns: 1;
  }
}

@media (max-width: 360px) {
  .footer-container .services-list-container {
    display: flex;
    flex-direction: column;
  }

  .footer-container .services-list-container .services-list-first {
    padding-right: 0;
    border-right: none;
    margin: 1rem 0 0 0;
  }

  .footer-container .services-list-container .services-list-second {
    padding-left: 0;
    margin: 0 0 1rem 0;
  }
}
