.endOfLeaseSecondary .endOfLeaseSecondary__item {
  padding: 1.2rem 0;
}

.endOfLeaseSecondary .endOfLeaseSecondary__item:first-child {
  border-bottom: 1px solid var(--light-color);
}

.endOfLeaseSecondary .endOfLeaseSecondary__item > p,
.endOfLeaseSecondary
  .endOfLeaseSecondary__item
  .endOfLeaseSecondary__options
  .endOfLeaseSecondary__option {
  font-size: 1rem;
}

.endOfLeaseSecondary
  .endOfLeaseSecondary__item
  .endOfLeaseSecondary__options
  .endOfLeaseSecondary__option {
  margin-top: 1rem;
}

.endOfLeaseSecondary .endOfLeaseSecondary__extras h2 {
  border-bottom: 1px solid var(--light-color);
}
