@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
.navbar-container .top-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 2rem;
}

.navbar-container .top-navbar .navbar-logo .logo {
  width: 100px;
}

.navbar-container .top-navbar .top-header-info {
  display: flex;
}

.navbar-container .top-navbar .top-header-info-item {
  display: flex;
  align-items: center;
  padding: 0 2rem;
}
.navbar-container .top-navbar .top-header-info-item:not(:last-child) {
  border-right: 1px solid var(--light-color);
}

.navbar-container .top-navbar .top-header-info-item .top-header-info-item-icon {
  padding: 5px;
  border: 1px dashed var(--light-color);
}

.navbar-container .top-navbar .top-header-info-item .social svg {
  width: 32px;
  height: 32px;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid var(--light-color);
  color: #4267b2;
}

.navbar-container
  .top-navbar
  .top-header-info-item
  .top-header-info-item-icon
  svg {
  font-size: 1.25rem;
  color: var(--light-color);
}

.navbar-container
  .top-navbar
  .top-header-info-item
  .top-header-info-item-detail {
  margin-left: 10px;
  font-weight: 500;
}

.navbar-container
  .top-navbar
  .top-header-info-item
  .top-header-info-item-detail
  p {
  font-size: 1.15rem;
}

.navbar-container .navbar-menu {
  width: 100%;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-container .navbar-menu .navbar-menu-toggle {
  display: none;
}

.navbar-container .navbar-menu .menu-list {
  list-style: none;
  display: flex;
  margin: 0;
}

.navbar-container .navbar-menu .menu-list .menu-item.has-sub-menu {
  position: relative;
}

.navbar-container .navbar-menu .menu-list .menu-item > a {
  padding: 6px 2rem;
  color: #ffffff !important;
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: center;
}

.navbar-container .navbar-menu .menu-list .menu-item .activeNavLink {
  font-weight: 600;
}

.navbar-container .navbar-menu .menu-list .menu-item:not(:last-child) > a {
  border-right: 2px solid var(--dark-color);
}

.navbar-container a {
  position: relative;
}

.navbar-container .activeNavLink .top-bar,
.navbar-container a:hover .top-bar {
  position: absolute;
  left: 0;
  right: 0;

  margin-left: auto;
  margin-right: auto;
  top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid rgba(255, 255, 255, 0);
  border-right: 8px solid rgba(255, 255, 255, 0);

  border-top: 8px solid rgb(255, 255, 255);
  transition: 0.1s;
}

.navbar-container .navbar-menu .menu-item .sub-menu {
  display: none;
}

.navbar-container .navbar-menu .menu-item:hover .sub-menu {
  display: block;
  position: absolute;
  top: 2.4rem;
  /* width: 250px; */
  z-index: 99;
}

.navbar-menu .menu-item .sub-menu .sub-menu-list {
  margin-top: 8px;
  list-style: none;
  background-color: var(--primary-color);
  color: #ffffff;
  padding: 10px 1rem;
  line-height: 1.5rem;
  /* column-count: 2; */
  /* column-gap: 20px; */
}

.navbar-menu .menu-item .sub-menu .sub-menu-list li {
  width: 250px;
}

.navbar-menu .sub-menu .sub-menu-list .sub-menu-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: #ffffff !important;
}

.navbar-menu .sub-menu .sub-menu-list .sub-menu-item svg {
  margin-right: 10px;
  font-size: 1rem;
}

.navbar-container .navbar-menu .request-quote-block {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4rem;
}

.navbar-container .navbar-menu .request-quote-block img {
  margin-top: -8px;
  width: 180px;
}

.navbar-container .navbar-menu .request-quote-block a {
  position: absolute;
  color: #ffffff !important;
  font-size: 1rem;
  font-weight: 600;
  margin-top: -22px;
}

.mob-menu {
  position: fixed;
  background: #f9f9f9;
  top: 0;
  left: -270px;
  width: 270px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 99;
  transition: all 0.2s;
}

.mob-menu.visible {
  left: 0;
}

.mob-menu .mob-menu-close {
  position: absolute;
  right: 0;
  margin: 1rem;
  font-size: 2rem;
}

.mob-menu .menu-list {
  list-style: none;
  padding-left: 0;
  margin-top: 3rem;
}

.mob-menu .menu-list .menu-item {
  border-bottom: 1px solid #eeeeee;
}

.mob-menu .menu-list .menu-item a {
  display: block;
  padding: 5px 14px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 30px;
  min-height: 32px;
}

.mob-menu .menu-list .menu-item.has-sub-menu a {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mob-menu .menu-list .mob-menu-dropdown {
  font-size: 1.5rem;
}

.mob-menu .menu-list .menu-item .sub-menu {
  display: none;
}

.mob-menu .menu-list .menu-item .sub-menu.visible {
  display: block;
}

.mob-menu .menu-list .menu-item .sub-menu .sub-menu-list {
  list-style: none;
  padding-left: 10px;
}

.mob-menu .menu-list .menu-item .sub-menu .sub-menu-list .sub-menu-item {
  color: var(--primary-color);
  border-bottom: 1px solid #eeeeee;
  display: block;
  padding: 10px 14px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 30px;
  min-height: 32px;
  display: flex;
  align-items: center;
}

.mob-menu
  .menu-list
  .menu-item
  .sub-menu
  .sub-menu-list
  .sub-menu-item:first-child {
  border-top: 1px solid #eeeeee;
}

.mob-menu .menu-list .menu-item .sub-menu .sub-menu-list .sub-menu-item svg {
  margin-right: 10px;
}

@media (max-width: 1180px) {
  .navbar-container .navbar-menu .navbar-menu-toggle {
    display: block;
    margin-left: 2rem;
  }

  .navbar-container .navbar-menu .navbar-menu-toggle svg {
    font-size: 2rem;
  }

  .navbar-container .navbar-menu .menu-list {
    display: none;
  }

  .navbar-container .navbar-menu .request-quote-block img {
    margin-top: -9px;
    width: 200px;
  }
}

@media (max-width: 820px) {
  .navbar-container .top-navbar {
    display: flex;
    align-items: center;
    margin: 0.5rem 1rem 1rem 1rem;
  }

  .navbar-container .top-navbar .top-header-info {
    display: flex;
    align-items: center;
  }

  .navbar-container .top-navbar .top-header-info-item .social {
    margin-top: 6px;
  }

  .navbar-container
    .top-navbar
    .top-header-info-item
    .top-header-info-item-detail {
    display: none;
  }

  .navbar-container .top-navbar .top-header-info-item {
    padding: 0 1rem 0 0;
  }

  .navbar-container
    .top-navbar
    .top-header-info-item
    .top-header-info-item-icon {
    padding: 5px;
  }

  .navbar-container .top-navbar .top-header-info-item:not(:last-child) {
    border: none;
  }
}

@media (max-width: 465px) {
  .navbar-container .top-navbar .top-header-info {
    display: flex;
    flex-direction: column;
  }

  .navbar-container
    .top-navbar
    .top-header-info-item
    .top-header-info-item-icon {
    padding: 5px;
  }

  .navbar-container .top-navbar .top-header-info-item .social svg {
    padding: 10px;
    border-radius: 50%;
  }

  .navbar-container
    .top-navbar
    .top-header-info-item
    .top-header-info-item-icon
    svg {
    font-size: 1rem;
  }

  .navbar-container
    .top-navbar
    .top-header-info-item
    .top-header-info-item-detail {
    font-size: 1rem;
  }

  .navbar-container .top-navbar .top-header-info-item .social svg {
    padding: 5px;
  }

  .navbar-container .navbar-menu .navbar-menu-toggle {
    margin-left: 1rem;
  }

  .navbar-container .navbar-menu .request-quote-block {
    margin-right: 1rem;
  }
}

.custom-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;
}

.custom-loader .ant-spin-dot-item {
  background-color: var(--color-primary);
}

.custom-loader .ant-spin-lg .ant-spin-dot {
  font-size: 50px;
}

.custom-loader .ant-spin-lg .ant-spin-dot i {
  width: 20px;
  height: 20px;
}

.vacancy-card-container {
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  border: 1px solid #b6b6b6;
  margin-bottom: 1rem;
}

.vacancy-card-container .vacancy-card-content {
  padding-right: 1rem;
}

.vacancy-card-container .vacancy-card-apply {
  min-width: 200px;
}

.vacancy-card-container .vacancy-card-apply .apply-button {
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
}

.vacancy-card-container .vacancy-card-apply .vacancy-location {
  margin-top: 10px !important;
  font-size: 1rem;
  text-align: center;
}

.vacancy-card-container .vacancy-card-content .vacancy-header {
  font-size: 1rem;
  font-weight: 500;
}

.vacancy-card-container .vacancy-card-content .vacancy-description {
  margin: 1rem 0 !important;
  font-size: 1rem;
  line-height: 2rem;
}

.vacancy-card-container
  .vacancy-card-content
  .vacancy-features
  .vacancy-features-list {
  display: flex;
}

.vacancy-card-container
  .vacancy-card-content
  .vacancy-features
  .vacancy-features-list
  div {
  display: flex;
  align-items: center;
  margin-right: 3rem;
  font-size: 1rem;
}

.vacancy-card-container
  .vacancy-card-content
  .vacancy-features
  .vacancy-features-list
  div
  span {
  margin-right: 10px;
  background-color: #000000;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
}

.btn-hidden {
  display: none !important;
}

#careersAppication .ant-form-item-label > label {
  width: 8rem;
  font-size: 0.8rem;
}

/* empty field error message */
#careersAppication .ant-form-item-explain-error {
  font-size: 0.8rem !important;
}
/* empty field error message */

#careersAppication .ant-btn:hover,
#careersAppication .ant-btn:focus {
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
}

/* form item */
@media screen and (max-width: 575px) {
  #careersAppication .ant-form-item {
    margin-bottom: 0.6rem !important;
  }

  #careersAppication .ant-form-item-label {
    padding: 0 !important;
  }
}
/* form item */

@media (max-width: 500px) {
  .vacancy-card-container {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  .vacancy-card-container .vacancy-card-apply {
    width: 120px;
    margin: 2rem auto 1rem auto;
  }
}

.hero {
  background-image: url(/static/media/hero_image.7d6cd1de.png);
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: cover;
  width: 100%;
  margin-bottom: 2rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
}

.hero .hero__container {
  width: 50%;
  height: 100%;
  padding: 4rem 2rem 4rem 3rem;
  background: linear-gradient(
    89.23deg,
    rgba(255, 255, 255, 0.78) 80.29%,
    rgba(196, 196, 196, 0) 109.82%
  );
}

.hero .hero__container .hero__header {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.hero .hero__container .hero__header h1 {
  font-weight: 700;
}

.hero .hero__container .hero__header > h1 > span {
  color: var(--primary-color);
}

.hero .hero__container .hero__body {
  text-align: justify;
  line-height: 2rem;
  font-size: 1.1rem;
  margin-bottom: 2rem;
}

.hero .hero__container .hero__bottom {
  display: flex;
}

.hero .hero__container .hero__bottom .ant-btn .hero_call_button {
  color: aliceblue;
}

.hero .hero__container .hero__bottom button {
  height: 3.2rem;
  font-size: 1rem;
}

.hero .hero__container .hero__bottom .hero__btnRequest {
  width: 14rem;
  margin-right: 2rem;
}

.hero .hero__container .hero__bottom .hero__btnCall {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 9rem;
}

.hero .hero__container .hero__bottom .hero__btnCall .hero__btnIcon {
  margin-right: 0.6rem;
}

.hero .hero__container .hero__bottom .hero__btnCall:hover {
  color: #ffffff !important;
}

.hero .hero__container .hero__bottom .hero__btnCall .hero__btnInfo {
  text-align: start;
}

.hero
  .hero__container
  .hero__bottom
  .hero__btnCall
  .hero__btnInfo
  p:first-child {
  font-weight: 700;
}

.hero
  .hero__container
  .hero__bottom
  .hero__btnCall
  .hero__btnInfo
  p:last-child {
  font-size: 0.8rem;
}

.hero
  .hero__container
  .hero__bottom
  .hero__btnCall
  .hero__btnInfo
  p:last-child
  span {
  font-weight: 700;
}

@media (max-width: 1425px) {
  .hero .hero__container .hero__header h1 {
    font-size: 1.5rem;
  }
}

@media (max-width: 1000px) {
  .hero .hero__container {
    width: 100%;
  }

  .hero .hero__container .hero__header h1 {
    font-size: 1.8rem;
  }

  .hero .hero__container .hero__body {
    font-size: 1rem;
    line-height: 1.8rem;
  }
}

@media (max-width: 750px) {
  .hero {
    background-position: 80% 0;
  }

  .hero .hero__container {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .hero .hero__container .hero__header {
    font-size: 1rem;
  }
}

@media (max-width: 500px) {
  .hero .hero__container .hero__bottom {
    flex-direction: column;
    align-items: center;
  }

  .hero .hero__container .hero__bottom button {
    width: 100% !important;
  }

  .hero .hero__container .hero__bottom .hero__btnRequest {
    margin-bottom: 1.2rem;
    margin-right: 0;
  }
}

@media (max-width: 465px) {
  .hero .hero__container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.services {
  padding: 2rem;
}

.services .content .services__header {
  margin-bottom: 1rem;
}

.services .content .services__header h3 {
  text-align: center;
  font-size: 1.5rem;
}

.services .content .services__header h3 span {
  color: var(--primary-color);
}

.services .content .services__body .services__bodyItem ul {
  -webkit-column-count: 3;
          column-count: 3;
  -webkit-column-width: 320px;
          column-width: 320px;
  list-style: inside;
  padding: 0;
}

.services .content .services__body .services__bodyItem ul li {
  list-style-type: none;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
          break-inside: avoid;
}

.services .content .services__body .services__bodyItem ul li a {
  color: #212121 !important;
}

@media (max-width: 465px) {
  .services {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.serviceListItem {
  display: flex;
  align-items: center;
  height: 3.8rem;
}

.serviceListItem .serviceListItem__icon {
  margin-right: 1rem;
  border: 2px solid var(--primary-color);
  height: 32px;
  min-width: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: var(--primary-color);
}

.serviceListItem .serviceListItem__header {
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: 500;
}

@media (max-width: 750px) {
  .serviceListItem {
    height: 3.2rem;
  }

  .serviceListItem .serviceListItem__header {
    font-size: 0.9rem;
    line-height: 1.4rem;
  }
}

.about-us-intro-container {
  display: flex;
  padding: 2rem 1rem;
}

.about-us-intro-container > div {
  flex-basis: 50%;
}

.about-us-intro-container .intro-image-container img {
  width: 100%;
}

.about-us-intro-container .intro-text-container .pre-header {
  font-size: 14px;
  color: var(--primary-color);
  font-weight: 600;
}

.about-us-intro-container .intro-text-container .header {
  font-size: 1.5rem;
}

.about-us-intro-container .intro-text-container .intro-description {
  padding: 1rem 0;
  color: #434343;
  font-size: 1rem;
  line-height: 2rem;
  text-align: justify;
}

@media (max-width: 800px) {
  .about-us-intro-container {
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
  }

  .about-us-intro-container .intro-image-container {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
}

@media (max-width: 400px) {
  .about-us-intro-container .intro-image-container {
    width: 100%;
    /* margin: 0 auto; */
    margin-bottom: 1rem;
  }
}

.about-us-cards-container {
  padding: 1rem;
  background-color: #f5f5f5;
}

.about-us-cards-container .header h3 {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
}

.about-us-cards-container .header h3 span {
  color: var(--primary-color);
  font-weight: 600;
}

.about-us-cards-container .about-us-cards-group {
  padding: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-column-gap: 2rem;
  -webkit-column-gap: 2rem;
          column-gap: 2rem;
  grid-row-gap: 2rem;
  row-gap: 2rem;
}

.about-us-cards-container .about-us-cards-group .about-us-card {
  min-width: 350px;
  text-align: center;
  max-width: 350px;
}

.about-us-cards-container .about-us-cards-group .about-us-card img {
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.about-us-cards-container .about-us-cards-group .about-us-card h5 {
  margin: 1rem auto !important;
  font-size: 1rem;
}

.about-us-cards-container .about-us-cards-group .about-us-card h5 span {
  color: var(--primary-color);
  font-weight: 600;
}

.about-us-cards-container
  .about-us-cards-group
  .about-us-card
  .about-us-card-description {
  color: #434343;
  font-size: 1rem;
  line-height: 1.75rem;
}

@media (max-width: 800px) {
  .about-us-cards-container .about-us-cards-group .about-us-card {
    min-width: 300px;
    text-align: center;
    max-width: 300px;
  }
}

.stats-container {
  background: url(/static/media/about-us-stats.48b4de8d.png) no-repeat center;
  width: 100%;
  padding: 2rem 1rem;
  background-size: cover;
  color: #ffffff;
}

.stats-container .info {
  font-size: 1rem;
  line-height: 2rem;
  text-align: center;
}

.stats-container .stats-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  grid-row-gap: 2rem;
  row-gap: 2rem;
  margin: 1rem 0;
}

.stats-container .stats-group .stats-item {
  text-align: center;
  min-width: 220px;
}

.stats-container .stats-group .stats-item .stats-number {
  font-size: 2rem;
  font-weight: 500;
  color: #00bc12;
}

.stats-container .stats-group .stats-item .stats-title {
  font-size: 1rem;
  text-transform: uppercase;
}

.whyChoose {
  padding: 4rem;
  background-color: #f5f5f5;
}

.whyChoose-isWhite {
  background-color: #ffffff;
}

.whyChoose .whyChoose__header {
  margin-bottom: 1rem;
}

.whyChoose .whyChoose__header h3 {
  text-align: center;
  font-size: 1.5rem;
}

.whyChoose .whyChoose__subtitle p {
  text-align: center;
  font-size: 1rem;
  line-height: 2rem;
  color: #434343;
}

.whyChoose .whyChoose__body {
  display: flex;
  justify-content: center;
  margin-top: 4rem;
}

.whyChoose .whyChoose__body .whyChoose__image {
  width: 65%;
}

.whyChoose .whyChoose__body .whyChoose__image img {
  width: 100%;
  height: auto;
}

@media (max-width: 1200px) {
  .whyChoose .whyChoose__header {
    margin-bottom: 1.4rem;
  }

  .whyChoose .whyChoose__body .whyChoose__image {
    width: 70%;
  }

  .whyChoose .whyChoose__subtitle p {
    font-size: 1rem;
  }
}

@media (max-width: 1180px) {
  .whyChoose {
    padding: 4rem 2rem;
  }
}

@media (max-width: 1000px) {
  .whyChoose .whyChoose__subtitle p {
    text-align: justify;
  }

  .whyChoose .whyChoose__subtitle p {
    line-height: 1.8rem;
  }
}

@media (max-width: 750px) {
  .whyChoose {
    padding: 2rem 1rem;
  }

  .whyChoose .whyChoose__body .whyChoose__image {
    width: 100%;
  }
}

.topClients {
  padding: 4rem 0;
}

.topClients .content .topClients__header {
  margin-bottom: 1rem;
}

.topClients .content .topClients__header h3 {
  text-align: center;
  font-size: 1.5rem;
}

.topClients .content .topClients__header h3 span {
  color: var(--primary-color);
}

.topClients .content .topClients__body {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.topClients .content .topClients__body .topClients__image {
  flex: 33.33% 1;
  max-width: 33.33%;
  text-align: center;
}

.topClients .content .topClients__body .topClients__image img {
  object-fit: contain;
  width: auto;
  height: auto;
  max-width: 200px;
  max-height: 150px;
}

@media screen and (max-width: 980px) {
  .topClients .content .topClients__body .topClients__image {
    flex: 50% 1;
    max-width: 50%;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 750px) {
  .topClients {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .topClients .content .topClients__body .topClients__image {
    flex: 100% 1;
    max-width: 100%;
  }
}

.qoute_container {
  padding: 2rem 2rem 3rem;
  background: #f5f5f5;
  margin: 40px 0 0 0;
}

.heading_style {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
}

.heading_style > span {
  font-weight: 700;
  line-height: 35px;
  color: #0f8015;
}

.qoute_container .qoute_col {
  text-align: center;
  padding: 0 50px;
}

.qoute_col:not(:last-child) {
  border-right: 1px solid #b6b6b6;
}

.qoute_header {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1rem;
  color: #212121;
}

.qoute_details {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 20px;
  color: #212121;
}

.content > h1 {
  color: #0f8015;
}

.button_container {
  text-align: center;
  margin-top: 20px;
}

.button_container > p {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 20px;
  color: #0f8015;
}

.button_container button {
  font-weight: 500;
}

.services-quote.ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}

.services-quote .ant-tabs-tab-btn {
  font-size: 1rem;
  color: #000000;
}

.services-quote .ant-tabs-tab-btn:hover {
  color: var(--primary-color);
}

.services-quote .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary-color);
}

.services-quote .ant-tabs-ink-bar {
  background: none;
}

@media (max-width: 1040px) {
  .qoute_col:not(:last-child) {
    border-right: none;
  }

  .qoute_col:nth-child(2n + 1) {
    border-right: 1px solid #b6b6b6;
    padding-bottom: 20px;
  }
}

@media (max-width: 750px) {
  .qoute_col:not(:last-child) {
    border-right: none;
  }

  /* .qoute_col:nth-child(2n + 1) {
    border-right: 1px solid #b6b6b6;
    padding-bottom: 20px;
  } */
}

@media screen and (max-width: 500px) {
  .qoute_col:not(:last-child) {
    border-right: none;
    padding-bottom: 20px;
  }

  .qoute_col:nth-child(2n + 1) {
    border-right: none;
    padding-bottom: 20px;
  }
}

.email-quote-container .get-quote-image-container {
  margin: 1rem auto;
  text-align: center;
}

.email-quote-container .description {
  font-size: 1rem;
  text-align: center;
  color: #434343;
}

.email-quote-container .email-quote-form-container {
  margin: 1rem 0;
}

.email-quote-container .email-quote-form-container .email-quote-form {
  width: 100%;
  margin: 0 auto;
}

.email-quote-form-container .email-quote-form .get-quote-button-container {
  text-align: center;
}

.email-quote-form-container
  .email-quote-form
  .get-quote-button-container
  .get-quote-button {
  padding: 8px 50px;
  height: 38px;
}

@media (min-width: 600px) {
  .email-quote-container .email-quote-form-container .email-quote-form {
    width: 60%;
  }
}
.generate-quote-container .generate-quote-image-container {
  margin: 1rem auto;
  text-align: center;
}

.generate-quote-container .description {
  font-size: 1rem;
  text-align: center;
  color: #434343;
}

.generate-quote-container .generate-quote-form-container {
  margin: 1rem 0;
}

.generate-quote-container .generate-quote-form-container .generate-quote-form {
  width: 100%;
  margin: 0 auto;
}

.generate-quote-form-container
  .generate-quote-form
  .get-quote-button-container {
  text-align: center;
}

.generate-quote-form-container
  .generate-quote-form
  .get-quote-button-container
  .get-quote-button {
  padding: 8px 50px;
  height: 38px;
}

@media (min-width: 600px) {
  .generate-quote-container .generate-quote-form-container .generate-quote-form {
    width: 60%
  }
}
.covidCleaner {
  display: flex;
  grid-column-gap: 4rem;
  -webkit-column-gap: 4rem;
          column-gap: 4rem;
}

.covidCleaner .covidCleaner__container p {
  padding-bottom: 1rem;
  font-size: 1rem;
  line-height: 2rem;
  text-align: justify;
}

.covidCleaner .covidCleaner__container .covidCleaner__header {
  margin: 1rem 0;
}

.covidCleaner .covidCleaner__container .covidCleaner__header h2 {
  color: var(--primary-color);
}

.covidCleaner .covidCleaner__container .covidCleaner__description ul {
  padding: 0 1rem;
}

.covidCleaner .covidCleaner__container .covidCleaner__description ul li {
  font-size: 1rem;
  line-height: 2rem;
}

.covidCleaner .covidCleaner__image {
  min-width: 400px;
  max-width: 400px;
}

.covidCleaner .covidCleaner__image img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 900px) {
  .covidCleaner {
    display: block;
  }

  .covidCleaner .covidCleaner__container {
    margin-bottom: 2rem;
  }

  .covidCleaner .covidCleaner__container p {
    padding: 0;
  }
}

@media screen and (max-width: 480px) {
  .covidCleaner .covidCleaner__image {
    min-width: 150px;
  }
}

.covidService {
  background-color: #329137;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.covidService > p {
  padding-bottom: 1rem;
  font-weight: 700;
  font-size: 1rem;
}

.covidService .covidService__contacts {
  display: flex;
  width: 30%;
  justify-content: center;
}

.covidService .covidService__contacts .covidService__contactItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.covidService .covidService__contactItem h2 a {
  color: #ffffff !important;
}

@media screen and (min-width: 1300px) {
  .covidService .covidService__contacts {
    width: 25%;
  }
}

@media screen and (max-width: 1300px) {
  .covidService .covidService__contacts {
    width: 40%;
  }
}

@media screen and (max-width: 800px) {
  .covidService .covidService__contacts {
    width: 60%;
  }
}

@media screen and (max-width: 550px) {
  .covidService .covidService__contacts {
    width: 90%;
  }
}

@media screen and (max-width: 420px) {
  .covidService .covidService__contactItem h2 {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 340px) {
  .covidService > p {
    font-size: 0.9rem;
  }

  .covidService .covidService__contacts {
    width: 100%;
  }

  .covidService .covidService__contactItem h2 {
    font-size: 1rem;
  }
}

.endOfLease {
  display: flex;
}

.endOfLease .endOfLease__containerLeft {
  margin-right: 2rem;
}

.endOfLease .endOfLease__containerRight {
  width: 800px;
}

@media screen and (max-width: 1000px) {
  .endOfLease .endOfLease__containerRight {
    width: 880px;
  }
}

@media screen and (max-width: 845px) {
  .endOfLease {
    flex-direction: column;
  }

  .endOfLease .endOfLease__containerLeft {
    margin-right: 0;
  }

  .endOfLease .endOfLease__containerRight {
    width: 100%;
  }
}

/* .EOL_Content{
    display: flex;
    gap: 20px;
} */

.EndOfLeasePrimary_container {
  padding: 1.2rem 0;
}

.EndOfLeasePrimary_container h2 {
  margin-bottom: 1rem !important;
  color: var(--primary-color);
}

.EndOfLeasePrimary_container .EndOfLeasePrimary_content .ant-radio-group,
.ant-space {
  width: 100%;
}

.EndOfLeasePrimary_container
  .EndOfLeasePrimary_content
  .ant-radio-group
  .ant-space
  .radioUnit_conatainer
  .radioUnit_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}

.EndOfLeasePrimary_container
  .EndOfLeasePrimary_content
  .ant-radio-group
  .ant-space
  .radioUnit_conatainer
  .radioUnit_item
  h4,
span.ant-radio + * {
  font-size: 1rem;
}

.EndOfLeasePrimary_container
  .EndOfLeasePrimary_content
  .ant-radio-group
  .ant-space
  .radioUnit_conatainer
  .radioUnit_item
  span {
  font-size: 0.95rem;
}

.EndOfLeasePrimary_container
  .EndOfLeasePrimary_content
  .ant-radio-group
  .ant-space
  .radioUnit_conatainer
  .radioUnit_item
  .ant-btn {
  margin: 0 20px;
}

hr {
  width: 100%;
}

.radio_option {
  border-bottom: 1px solid var(--light-color);
  padding: 1.2rem 0;
}

.endOfLeaseSecondary .endOfLeaseSecondary__item {
  padding: 1.2rem 0;
}

.endOfLeaseSecondary .endOfLeaseSecondary__item:first-child {
  border-bottom: 1px solid var(--light-color);
}

.endOfLeaseSecondary .endOfLeaseSecondary__item > p,
.endOfLeaseSecondary
  .endOfLeaseSecondary__item
  .endOfLeaseSecondary__options
  .endOfLeaseSecondary__option {
  font-size: 1rem;
}

.endOfLeaseSecondary
  .endOfLeaseSecondary__item
  .endOfLeaseSecondary__options
  .endOfLeaseSecondary__option {
  margin-top: 1rem;
}

.endOfLeaseSecondary .endOfLeaseSecondary__extras h2 {
  border-bottom: 1px solid var(--light-color);
}

.Card_container{
    background: #fff;
    position: relative;
    border-radius: 8px;
    margin-bottom: 30px;
    box-shadow: 0 3px 8px 0 silver;
    
    height: 320px;
    margin-top: 2rem;
}

.Card_container .EOL_Card_Header {
  background-image: url(https://files.dxr.cloud/Dv539m7Xds3onFCkoJperelz413FgrclEmjza1Gc9yPIaba8TU8CTFmCuUzK);
  height: 40%;
  z-index: 1;
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 0;
  top: 0;
  left: 50%;
  -webkit-mask-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#000),
    to(rgba(0, 0, 0, 0))
  );
  /* border-bottom: 1px solid black; */
}
.Card_container h2 {
    position: absolute;
    color: var(--primary-color);
    left: 30px;
    top: 20px;
    text-transform: uppercase;
}

hr{
    position: absolute;
    top: 40%;
}

.Card_container .EOL_Card_content{
    height: 60%;
    position: absolute;
    top:50%;
    padding: 20px 30px;

}

.extrasItems {
  padding: 0.6rem;
  padding-left: 0;
}

.extrasItem {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin: 0.8rem;
}

.extrasItem .extrasItem__icon {
  font-size: 3.4rem;
  color: var(--primary-color);
}

.bookingSummary {
  border: 1px solid var(--light-color);
  padding: 1.6rem;
  padding-bottom: 0.4rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  margin: 1rem 0;
}

.bookingSummary .bookingSummary__caption {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 2px solid var(--light-color);
}

.bookingSummary .bookingSummary__caption > span:first-child {
  font-weight: 700;
}

.bookingSummary .bookingSummary__caption > span:last-child {
  text-transform: uppercase;
}

.bookingSummary .bookingSummary__total {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.bookingSummary .bookingSummary__total > p {
  font-weight: 700;
}

.bookingSummary .bookingSummary__total > span {
  font-size: 2rem;
  color: var(--primary-color);
}

.carpetSteamOptions {
  -webkit-column-count: 2;
          column-count: 2;
  grid-column-gap: 3rem;
  -webkit-column-gap: 3rem;
          column-gap: 3rem;
  -webkit-column-width: 260px;
          column-width: 260px;
  padding: 1rem 0;
}

.radioDropOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  line-height: 1.4rem;
}

.radioDropOption > p {
  width: 55%;
}

.radioDropOption .radioDropOption__count {
  display: flex;
}

.radioDropOption .radioDropOption__count .radioDropOption__btn {
  min-height: 50px;
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radioDropOption .radioDropOption__count > span {
  font-weight: 700;
  width: 20px;
  margin: auto 0rem;
  text-align: center;
}

.dashboard {
  display: flex;
  font-size: 1rem;
}

.dashboard .dashboard__content {
  padding: 2rem 4%;
  background-color: #f5f5f5;
  width: 100%;
}

.dashboard .dashboard__content .dashboard__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard .dashboard__content .dashboard__header .dashboard__mobMenuOpen {
  font-size: 1.6rem;
}

@media screen and (min-width: 940px) {
  .dashboard .dashboard__sidebar {
    width: 350px;
    height: 100%;
  }

  .dashboard .dashboard__content .dashboard__header {
    display: none;
  }
}

.sidebar {
  height: 100vh;
}

.sidebar .sidebar__menu {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  height: 100%;
}

.sidebar .sidebar__mobMenu {
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 0.4s all;
  z-index: 999;
  height: 100%;
  width: 250px;
}

.sidebar .sidebar__mobMenu .sidebar__mobMenuClose {
  text-align: right;
  font-size: 1.6rem;
  padding: 0.2rem 0.2rem 0 0;
}

.sidebar .sidebar__mobileMenuActive {
  left: 0;
  transition: 0.2s all;
}

.sidebar .sidebar__menu .sidebar__logo,
.sidebar .sidebar__mobMenu .sidebar__logo {
  text-align: center;
}

.sidebar .sidebar__menu .sidebar__logo img,
.sidebar .sidebar__mobMenu .sidebar__logo img {
  width: 100px;
}

.sidebar .sidebar__menu .sidebar__tabs,
.sidebar .sidebar__mobMenu .sidebar__tabs {
  margin-top: 1rem;
}

.sidebar .sidebar__menu .sidebar__tabs a,
.sidebar .sidebar__mobMenu .sidebar__tabs a {
  padding: 0.8rem 1.6rem;
  display: flex;
  cursor: pointer;
  transition: 0s all;
  margin-top: 1px;
}

.sidebar .sidebar__mobMenu .sidebar__tabs a {
  padding: 0.6rem;
}

.sidebar .sidebar__menu .sidebar__tabs a:hover,
.sidebar .sidebar__mobMenu .sidebar__tabs a:hover {
  background-color: var(--primary-color);
  color: #ffffff !important;
}

.sidebar .sidebar__menu .sidebar__tabs .sidebar__activeNavLink,
.sidebar .sidebar__mobMenu .sidebar__tabs .sidebar__activeNavLink {
  background-color: var(--primary-color) !important;
  color: #ffffff !important;
}

@media screen and (max-width: 940px) {
  .sidebar .sidebar__menu {
    display: none;
  }
}

@media screen and (min-width: 940px) {
  .sidebar .sidebar__mobMenu {
    display: none;
  }
}

.dashServicesItem {
  background: #ffffff;
  /* border: 1px solid #cfcfcf; */
  border-radius: 5px;
  padding: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  height: 100%;
}

.dashServicesItem:hover {
  background-color: var(--primary-color);
  color: #ffffff;
  transition: 0.2s all;
}

@media screen and (max-width: 290px) {
  .dashServicesItem {
    padding: 1rem;
  }
}

.dashboardLanding .dashboardLanding__body > ul {
  list-style: none;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .dashboardLanding .dashboardLanding__body > ul {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
  }
}

.dashboardHeader {
  margin-bottom: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboardHeader .dashboardHeader__head {
  display: flex;
  align-items: center;
}

.dashboardHeader .dashboardHeader__head > h1 {
  color: var(--primary-color);
  text-transform: uppercase;
  font-size: 1.6rem;
}

.dashboardHeader .dashboardHeader__head .dashboardHeader__backBtn {
  min-width: 3rem;
}

.dashboardHeader .dashboardHeader__head .dashboardHeader__backBtn button {
  padding: 0 0.6rem;
}

@media screen and (max-width: 940px) {
  .dashboardHeader {
    margin-bottom: 1.2rem;
    margin-top: 1.6rem;
  }

  .dashboardHeader > .dashboardHeader__head > h1 {
    font-size: 1.3rem;
  }

  .dashboardHeader .dashboardHeader__btn {
    display: none;
  }
}

.notificationbody {
  display: flex;
  align-items: center;
  padding: 1rem;
  font-size: 0.9rem;
  /* color: blue; */
  margin-top: 0.5%;
}

.notificationbody:hover {
  /* background: blue; */
  color: blue;
  cursor: pointer;
}
.notificationicon:hover {
  color: blue;
  cursor: pointer;
}

.notificationbody .notificationicon {
  width: 15%;
}
.notificationbody .notificationitem {
  width: 100%;
}
.notificationbody .notificationactive {
  width: 10%;
  justify-content: flex-end;
}
.notificationactive {
}

.notifications li {
  list-style: none;
  border-bottom: 1px solid var(--light-color);
}
.notification_showmore_button {
  margin-top: 3%;
  margin-left: 43%;
  background-color: green;
  color: white;
  border: none;
  border-radius: 0.5rem;
  width: 100px;
  height: 30px;
}


.ourServices .ourServices__body > ul {
  list-style: none;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .ourServices .ourServices__body > ul {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
  }
}

.termsAndConditions .termsAndConditions__body {
  background-color: #ffffff;
  padding: 1rem 1.4rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  border-radius: 5px;
}

.termsAndConditions .termsAndConditions__body h3 {
  text-transform: uppercase;
  color: var(--primary-color);
}

.termsAndConditions .termsAndConditions__body p {
  line-height: 2rem;
  text-align: justify;
  padding-top: 0.4rem;
  padding-bottom: 1.4rem;
  font-size: 0.9rem;
}

.contactUs .contactUs__body {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 5px;
}

.contactUs .contactUs__body .contactUs__details {
  margin-bottom: 2rem;
}

.contactUs .contactUs__body .contactUs__details .contactUs__detail {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.contactUs .contactUs__body .contactUs__details .contactUs__detail svg {
  margin-right: 0.5rem;
}

.headerButton button {
  height: 3.2rem;
  width: 11rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
}

.headerButton button .anticon {
  margin: 0 !important;
}

.headerButton button svg {
  font-size: 0.8rem;
}

.service .service__body {
  background-color: #ffffff;
  padding: 1rem 1.4rem;
  border-radius: 5px;
  font-size: 0.9rem;
}

.service .service__body > p {
  line-height: 2rem;
  text-align: justify;
}

.service .service__body > p:nth-child(2) {
  padding: 1rem 0 0.6rem 0;
}

.service .service__body ul li {
  line-height: 1.8rem;
  list-style-position: inside;
}

.document .document__body {
    background-color: #ffffff;
    border-radius: 5px;
  }
  
  .document .document__body .document__bodyHead {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid var(--light-color);
    padding: 1rem;
    font-weight: 500;
  }
  
  .document .document__body .document__bodyHead p:first-child {
    width: 45%;
  }
  .document .document__body .document__bodyHead p:nth-child(2) {
    width: 25%;
  }
  .document .document__body .document__bodyHead p:nth-child(3) {
    width: 30%;
    text-align: end;
  }
  
  .document .document__body ul li {
    list-style: none;
    border-bottom: 1px solid var(--light-color);
  }
  
  .document .document__body ul li:last-child {
    border-bottom: none;
  }
  
  .document .document__footer {
    display: flex;
    align-items: center;
    margin-top: 1.4rem;
  }
  
  .document .document__footer > p {
    padding-left: 0.4rem;
    font-size: 0.8rem;
  }
  .documentreport_showmore{
      /* color: red; */
  }
  
  @media screen and (max-width: 620px) {
    .document .document__body .document__bodyHead {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      font-size: 0.9rem;
    }
  }
  
.record {
  display: flex;
  align-items: center;
  padding: 1rem;
  font-size: 0.9rem;
}

.record .record__name {
  width: 45%;
}

.record .record__date {
  width: 25%;
}

.record .record__options {
  display: flex;
  justify-content: flex-end;
  width: 30%;
}

.record .record__options .record__btn button {
  width: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.record .record__options .record__btn:first-child {
  margin-right: 0.4rem;
}

@media screen and (max-width: 620px) {
  .record {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.8rem;
  }
}

.report_showmore{
    margin-left: 43%;
    background-color:green;
    color: white;
    height: 30px;
    width: 100px;
    border: none;
}




.feedback_container .feedback_content {
  background-color: #ffffff;
  padding: 1.5rem 1rem;
  border-radius: 5px;
}

.documentreport .documentreport__body {
  background-color: #ffffff;
  border-radius: 5px;
}

.documentreport .documentreport__body .documentreport__bodyHead {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid var(--light-color);
  padding: 1rem;
  font-weight: 500;
}

.documentreport .documentreport__body .documentreport__bodyHead p:first-child {
  width: 45%;
}
.documentreport .documentreport__body .documentreport__bodyHead p:nth-child(2) {
  width: 25%;
}
.documentreport .documentreport__body .documentreport__bodyHead p:nth-child(3) {
  width: 30%;
  text-align: end;
}

.documentreport .documentreport__body ul li {
  list-style: none;
  border-bottom: 1px solid var(--light-color);
}

.documentreport .documentreport__body ul li:last-child {
  border-bottom: none;
}

.documentreport .documentreport__footer {
  display: flex;
  align-items: center;
  margin-top: 1.4rem;
}

.documentreport .documentreport__footer > p {
  padding-left: 0.4rem;
  font-size: 0.8rem;
}
.document_showmore_button{
  margin-left: 43%;
  background-color: green;
  color: white;
  border: none;
  border-radius: 0.5rem;
  width: 100px;
  height: 30px;
}

@media screen and (max-width: 620px) {
  .documentreport .documentreport__body .documentreport__bodyHead {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.9rem;
  }
}

.viewmodel .viewmodel__body {
    background-color: #ffffff;
    border-radius: 5px;
  }
  
  .viewmodel .viewmodel__body .viewmodel__bodyHead {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid var(--light-color);
    padding: 1rem;
    font-weight: 500;
  }
  
  .viewmodel .viewmodel__body .viewmodel__bodyHead p:first-child {
    width: 45%;
  }
  .viewmodel .viewmodel__body .viewmodel__bodyHead p:nth-child(2) {
    /* width: 25%; */
    width: 30%;
    text-align: end;
  }
  /* .viewmodel .viewmodel__body .viewmodel__bodyHead p:nth-child(3) {
  
  } */
  
  .viewmodel .viewmodel__body ul li {
    list-style: none;
    border-bottom: 1px solid var(--light-color);
  }
  
  .viewmodel .viewmodel__body ul li:last-child {
    border-bottom: none;
  }
  
  .viewmodel .viewmodel__footer {
    display: flex;
    align-items: center;
    margin-top: 1.4rem;
  }
  
  .viewmodel .viewmodel__footer > p {
    padding-left: 0.4rem;
    font-size: 0.8rem;
  }
    .viewmodel_showmore_button{
    margin-left: 43%;
    background-color: green;
    color: white;
    border: none;
    border-radius: 0.5rem;
    width: 100px;
    height: 30px;
  }

  
  @media screen and (max-width: 620px) {
    .viewmodel .viewmodel__body .viewmodel__bodyHead {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      font-size: 0.9rem;
    }
  }
  
.viewdata {
    display: flex;
    align-items: center;
    padding: 1rem;
    font-size: 0.9rem;
  }
  
  .viewdata .viewdata__name {
    width: 45%;
  }
  
  .viewdata .viewdata__options {
    display: flex;
    justify-content: flex-end;
    width: 60%;
  }
  
  .viewdata .viewdata__options .viewdata__btn button {
    width: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .viewdata .viewdata__options .viewdata__btn:first-child {
    margin-right: 0.4rem;
  }
  
  @media screen and (max-width: 620px) {
    .viewdata {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      font-size: 0.8rem;
    }
  }
  
.uploadFile {
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1.5rem 1rem;
}

.uploadFile .uploadFile__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2.5rem;
}

.uploadFile .uploadFile__body .uploadFile__icon {
  font-size: 3rem;
  height: 12rem;
  width: 12rem;
  cursor: pointer;
}

.uploadFile .uploadFile__body .uploadFile__icon svg {
  color: var(--primary-color);
  font-size: 3rem;
}

.uploadFile .uploadFile__body .uploadFile__icon:hover {
  border: 2px solid var(--secondary-color);
}

.uploadFile .uploadFile__btn {
  text-align: center;
}

.uploadFile .uploadFile__btn > button {
  height: 2.2rem;
  width: 12rem;
}

/* .ant-upload.ant-upload-select {
  display: flex !important;
  justify-content: center !important;
} */

.ant-upload-list-text .ant-upload-list-item-name {
  color: var(--primary-color) !important;
}

/* .ant-upload-list-item-info svg */

.profile .profile__container {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 1.5rem 2rem;
  border-radius: 5px;
}

.profile .profile__container .profile__avatar {
  flex: 0.3 1;
  padding-top: 1rem;
}

.profile .profile__container .profile__userForm {
  flex: 0.65 1;
}

.profile .profile__handles {
  margin-top: 0.4rem;
  text-align: right;
}

.profile .profile__handles .profile__btns {
  margin-top: 1rem;
  width: 100%;
}

.profile .profile__handles .profile__btns button {
  width: 9rem;
  height: 2.3rem;
}

.profile .profile__handles .profile__btns button:last-child {
  margin-left: 0.8rem;
}

@media screen and (max-width: 420px) {
  .profile .profile__handles .profile__btns {
    display: flex;
    flex-direction: column-reverse;
  }

  .profile .profile__handles .profile__btns button {
    width: 100%;
  }

  .profile .profile__handles .profile__btns button:last-child {
    margin-left: 0rem;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 680px) {
  .profile .profile__container {
    flex-direction: column;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }

  .profile .profile__container .profile__avatar {
    margin-bottom: 2rem;
  }
}

.avatarProf {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatarProf button {
  margin-top: 1.5rem;
  width: 13rem;
  height: 2.3rem;
}

/* .formLabel {
  margin: 0;
} */

.formLabel .ant-col-24.ant-form-item-label {
  padding: 0 !important;
  padding-top: 0rem !important;
}

.formLabel .ant-col-24.ant-form-item-label > label {
  font-size: 0.8rem;
}

.messageEditor {
  background-color: #ffffff;
  padding: 1.5rem 1rem;
  border-radius: 5px;
}

.messageEditor .messageEditor__editor {
  border: 1px solid var(--light-color);
  padding: 2px;
  min-height: 400px;
  margin: 1.5rem 0;
  padding: 0.5rem;
}

.messageEditor .messageEditor__btns {
  text-align: end;
}

.messageEditor .messageEditor__btns > button {
  width: 9rem;
  height: 2.3rem;
}

.messageEditor .messageEditor__btns > button:first-child {
  margin-right: 0.8rem;
}

@media screen and (max-width: 420px) {
  .messageEditor .messageEditor__btns {
    display: flex;
    flex-direction: column-reverse;
  }

  .messageEditor .messageEditor__btns > button {
    width: 100%;
  }

  .messageEditor .messageEditor__btns > button:first-child {
    margin-right: 0;
    margin-top: 0.8rem;
  }
}

.dashboardLanding .dashboardLanding__body > ul {
    list-style: none;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    text-align: center;
  }
  
  @media screen and (max-width: 500px) {
    .dashboardLanding .dashboardLanding__body > ul {
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 1rem;
      grid-column-gap: 1rem;
    }
  }
.dashServicesItem {
    background: #ffffff;
    /* border: 1px solid #cfcfcf; */
    border-radius: 5px;
    padding: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    height: 100%;
  }
  
  .dashServicesItem:hover {
    background-color: var(--primary-color);
    color: #ffffff;
    transition: 0.2s all;
  }
  
  @media screen and (max-width: 290px) {
    .dashServicesItem {
      padding: 1rem;
    }
  }
.dashboard {
  display: flex;
  font-size: 1rem;
}

.dashboard .dashboard__content {
  padding: 2rem 4%;
  background-color: #f5f5f5;
  width: 100%;
}

.dashboard .dashboard__content .dashboard__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dashboard .dashboard__content .dashboard__header .dashboard__mobMenuOpen {
  font-size: 1.6rem;
}

@media screen and (min-width: 940px) {
  .dashboard .dashboard__sidebar {
    width: 350px;
    height: 100%;
  }

  .dashboard .dashboard__content .dashboard__header {
    display: none;
  }
}

.dashboardLanding .dashboardLanding__body > ul {
  list-style: none;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .dashboardLanding .dashboardLanding__body > ul {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
  }
}






.subpageSites .subpageSites__body {
  background-color: #ffffff;
  border: 5px;
}

.subpageSites .subpageSites__body > h4 {
  border-bottom: 1px solid var(--light-color);
  line-height: 3.5rem;
  padding: 0 1rem;
}

.uploadFileSites {
  padding: 1rem;
}

.uploadFileSites
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #0f8015;
  border-color: #0f8015;
}

.uploadFileSites
  .ant-radio-group-solid
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #0f8015;
  border-color: #0f8015;
}

.uploadFileSites .ant-radio-group-solid :hover {
  color: #fff;
  background: #0f8015;
  border-color: #0f8015;
}

.uploadFileSites .uploadFileSites__upload,
.uploadFileSites .uploadFileSites__issueUpload {
  text-align: center;
}

.uploadFileSites .uploadFileSites__issueUpload {
  margin-bottom: 0.5rem;
}

.uploadFileSites .uploadFileSites__upload .uploadFileSites__icon,
.uploadFileSites .uploadFileSites__issueUpload .uploadFileSites__icon {
  height: 12rem;
  width: 12rem;
  margin: 1.5rem auto 0.8rem auto;
}

.uploadFileSites .uploadFileSites__upload .uploadFileSites__icon svg,
.uploadFileSites .uploadFileSites__issueUpload .uploadFileSites__icon svg {
  color: var(--primary-color);
  font-size: 3rem;
}

.uploadFileSites .uploadFileSites__upload .uploadFileSites__icon:hover,
.uploadFileSites .uploadFileSites__issueUpload .uploadFileSites__icon:hover {
  border: 2px solid var(--secondary-color);
}

.uploadFileSites .uploadFileSites__upload .uploadFileSites__uploadBtn button,
.uploadFileSites
  .uploadFileSites__issueUpload
  .uploadFileSites__uploadBtn
  button {
  width: 12rem;
  height: 2.5rem;
}

.uploadFileSites .uploadFileSites__query {
  display: flex;
  align-items: center;
}

.uploadFileSites
  .uploadFileSites__query
  .ant-checkbox-checked
  .ant-checkbox-inner {
  background-color: var(--primary-color);
}

.uploadFileSites .uploadFileSites__footerBtns {
  text-align: end;
}

.uploadFileSites .uploadFileSites__footerBtns button {
  height: 3rem;
  width: 12rem;
}

.uploadFileSites .uploadFileSites__footerBtns button:last-child {
  margin-left: 1rem;
  background-color: #ff1919;
  color: #ffffff;
}

.uploadFileSites .uploadFileSites__footerBtns button:last-child:hover {
  background-color: #ffffff;
  color: #ff1919;
  border-color: #ff1919;
}

@media screen and (max-width: 480px) {
  .uploadFileSites .uploadFileSites__footerBtns {
    display: flex;
    flex-direction: column;
  }

  .uploadFileSites .uploadFileSites__footerBtns button:last-child {
    margin: 0.8rem 0 0 0;
  }

  .uploadFileSites .uploadFileSites__footerBtns button {
    width: 100%;
  }
}

.document .document__body {
  background-color: #ffffff;
  border-radius: 5px;
}

.document .document__body .document__bodyHead {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid var(--light-color);
  padding: 1rem;
  font-weight: 500;
}

.document .document__body .document__bodyHead p:first-child {
  width: 45%;
}
.document .document__body .document__bodyHead p:nth-child(2) {
  width: 25%;
}
.document .document__body .document__bodyHead p:nth-child(3) {
  width: 30%;
  text-align: end;
}

.document .document__body ul li {
  list-style: none;
  border-bottom: 1px solid var(--light-color);
}

.document .document__body ul li:last-child {
  border-bottom: none;
}

.document .document__footer {
  display: flex;
  align-items: center;
  margin-top: 1.4rem;
}

.document .document__footer > p {
  padding-left: 0.4rem;
  font-size: 0.8rem;
}

@media screen and (max-width: 620px) {
  .document .document__body .document__bodyHead {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.9rem;
  }
}

.record {
  display: flex;
  align-items: center;
  padding: 1rem;
  font-size: 0.9rem;
}

.record .record__name {
  width: 45%;
}

.record .record__date {
  width: 25%;
}

.record .record__options {
  display: flex;
  justify-content: flex-end;
  width: 30%;
}

.record .record__options .record__btn button {
  width: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.record .record__options .record__btn:first-child {
  margin-right: 0.4rem;
}

@media screen and (max-width: 620px) {
  .record {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.8rem;
  }
}

.footer-container {
  margin-top: 1rem;
  background: var(--dark-color);
  color: #ffffff;
  padding: 2rem;
}

.footer-container .logo {
  width: 150px;
  margin-bottom: 1rem;
}

.footer-container .footer-items-container {
  display: flex;
  justify-content: center;
}

.footer-container .footer-items-container .footer-item {
  min-width: 300px;
}

.footer-container .footer-items-container .footer-item .footer-item-header {
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
}

.footer-container .footer-items-container .footer-item .footer-logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-container .footer-items-container .footer-item .quick-links {
  list-style: none;
  padding-left: 0;
  margin: 1rem 0;
}

.footer-container
  .footer-items-container
  .footer-item
  .quick-links
  .quick-link-item
  a {
  font-size: 1rem;
  line-height: 2rem;
  color: #ffffff !important;
}

.footer-container .services-list-container {
  display: flex;
}

.footer-container .services-list-container .services-list {
  list-style: none;
  -webkit-columns: 2;
          columns: 2;
  grid-column-gap: 80px;
  -webkit-column-gap: 80px;
          column-gap: 80px;
  padding-left: 0;
  margin: 1rem 0;
  -webkit-column-rule: 1px solid #ffffff;
          column-rule: 1px solid #ffffff;
}

.footer-container .services-list-container .services-list a {
  font-size: 1rem;
  line-height: 2rem;
  color: #ffffff !important;
}

.footer-container .footer-items-container .footer-item .services-list a svg {
  margin-right: 10px;
}

.footer-copyright-container {
  background: var(--primary-color);
  color: #ffffff;
}

.footer-copyright-container .copyright {
  text-align: center;
  padding: 5px 0;
  font-size: 1rem;
}

@media (max-width: 1200px) {
  .footer-container .footer-items-container .footer-item {
    min-width: 200px;
  }
}

@media (max-width: 920px) {
  .footer-container .footer-items-container {
    display: flex;
    flex-wrap: wrap;
  }

  .footer-container .footer-items-container .footer-item {
    flex-basis: 50%;
  }
  .footer-container .footer-items-container .footer-services-item {
    flex-basis: 100%;
  }
}

@media (max-width: 520px) {
  .footer-container {
    padding: 1rem;
  }

  .footer-container .footer-items-container {
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .footer-container .footer-items-container .footer-item {
    flex-basis: 50%;
    min-width: 150px;
  }

  .footer-container .footer-items-container .footer-services-item {
    flex-basis: 100%;
  }

  .footer-container .services-list-container .services-list {
    -webkit-columns: 1;
            columns: 1;
  }
}

@media (max-width: 360px) {
  .footer-container .services-list-container {
    display: flex;
    flex-direction: column;
  }

  .footer-container .services-list-container .services-list-first {
    padding-right: 0;
    border-right: none;
    margin: 1rem 0 0 0;
  }

  .footer-container .services-list-container .services-list-second {
    padding-left: 0;
    margin: 0 0 1rem 0;
  }
}

.page-header-container {
  padding: 1.5rem 2rem;
  display: flex;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.page-header-container .overlay {
  position: absolute;
  width: 55%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(
    89.24deg,
    rgba(255, 255, 255, 0.78) 79.28%,
    rgba(196, 196, 196, 0) 108.45%
  );
  opacity: 50%;
  border-right: 50px;
}

.page-header-container .header-content {
  z-index: 1;
}

.page-header-container .header {
  color: var(--primary-color);
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
}

.page-header-container .breadcrumb {
  font-size: 1rem;
  /* font-weight: 500; */
}

.page-header-container .breadcrumb a {
  color: #000000 !important;
}

.page-header-container .breadcrumb a::after {
  content: "|";
  margin: 0 10px;
}

.page-header-container .header_text {
  width: 55%;
  padding: 10px 20px 10px 0;
  text-align: justify;
}

.page-header-container .header_button h3 {
  margin-top: 10px !important;
  font-weight: 600;
  color: var(--primary-color);
}

.page-header-container .header_button .call_btn {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.page-header-container .header_button .call_btn svg {
  margin-right: 5px;
}

@media (max-width: 745px) {
  .page-header-container .header {
    font-size: 1rem;
    font-weight: 400;
  }

  .page-header-container .header_text {
    width: 100%;
    padding: 10px 20px 10px 0;
    text-align: justify;
  }
}

.home__header .home__headerSpan {
  color: var(--primary-color);
}

.careers-container {
  padding: 2rem 0;
}

.careers-container .careers-info {
  text-align: center;
}

.careers-container .careers-info .careers-info-header span {
  color: var(--primary-color);
}

.careers-container .careers-info .careers-info-description {
  margin-top: 1rem;
  font-size: 1rem;
  text-align: justify;
}

.careers-container .vacancies-container {
  margin: 2rem 0;
}

.careers-container .vacancies-container .vacancies-container-header {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  margin: 1rem 0 !important;
}

.service_container {
  padding: 50px 2rem;
}


@media (max-width: 768px) {
  .service_container {
    text-align: -webkit-right;
  }
  .Service_category {
    display: none;
  }
  .service_card_col {
    margin: 45px 0 0 -16px;
  }

  /* .service_container .ant-col {
    margin: 0 auto;
  } */
}

@media (max-width: 420px) {
  .Service_category {
    display: none;
  }

  .service_card_col {
    margin: 45px 0 0 -16px;
  }
}

.card {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 45px;
}

.card_wrapper {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  width: 422px;
  height: 388.05px;
  display: flex;
  flex-direction: column;
  margin-top: 45px;
}

.card_header {
  background: #c4c4c4;
  width: 422px;
  height: 328px;
}

.card_footer {
  color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0 10px; */
  width: 100%;
  margin-bottom: 10px;
}

.service_card {
  margin: 0 auto !important;
}

@media (max-width: 420px) {
  .card_footer {
    height: 30.33px;
  }

  .ant-card {
    width: 340px !important;
  }
}

@media (max-width: 768px) {
  .ant-card {
    width: 320px !important;
    margin-left: 5px !important;
  }
}

@media (max-width: 320px) {
  .ant-card {
    width: 290px !important;
  }
}

/*  */

.service_category {
  min-width: 24rem;
}

.service_category_header {
  text-align: center;
  background: #0f8015;
  padding: 10px 0;
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.1), 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.service_category_header h1 {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #ffffff;
}

.service_category_body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.1), 2px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
}

.service_category_body ul li {
  list-style-type: none;
  font-size: 1rem;
  line-height: 25px;
  text-align: justify;
  color: #434343;
  padding: 5px 0;
  cursor: pointer;
}



.service_category_body ul li a {
  color: #434343 ;
}

.service_category_body ul li a:hover {
  color: #0f8015 !important;
}

@media (max-width: 750px) {
  .service_category {
    min-width: 22rem;
  }

  .service_category_body ul li {
    font-size: 14px;
  }
}

@media (min-width: 750px) {
  .service_category {
    min-width: 22rem;
  }
}

@media (max-width: 500px) {
  .service_category {
    min-width: 18rem;
  }
}

.aboutUs__headerSpan {
  color: var(--primary-color);
}

.contact-container {
  padding: 2rem 0;
}

.contact-container .contact-header {
  text-align: center;
}

.contact-container .contact-header p {
  font-size: 14px;
  font-weight: 500;
}

.contact-container .contact-header h1 span {
  color: var(--primary-color);
}

.contact-container .contact-form-container {
  display: flex;
  justify-content: center;
  grid-column-gap: 100px;
  -webkit-column-gap: 100px;
          column-gap: 100px;
  margin: 2rem 0;
}

.contact-container .contact-form-container > div {
  flex-basis: 40%;
}

.contact-container .contact-form-container .contact-form form {
  border: 1px solid #b6b6b6;
  padding: 2rem 1rem;
}

.contact-container .contact-form-container .contact-form form .submit-button {
  width: auto;
  height: auto;
  padding: 8px 40px;
  margin-top: 1rem;
  font-size: 1rem;
}

.contact-form form input,
.contact-form form input:hover,
.contact-form form textarea,
.contact-form form textarea :hover {
  border: none;
  border-bottom: 1.5px solid #000000 !important;
}

.contact-form form input:focus,
.contact-form form textarea:focus {
  border: none;
  border-bottom: 1.5px solid var(--primary-color) !important;
  box-shadow: none;
}

.contact-container .contact-info {
  display: flex;
  flex-direction: column;
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.contact-container .contact-info .info-with-icon {
  display: flex;
  align-items: center;
}

.contact-container .contact-info .info-with-icon a {
  display: block;
  color: #000000 !important;
}

.contact-container .contact-info .info-with-icon svg {
  margin-right: 10px;
  font-size: 1rem;
}

.contact-container .contact-info .info-with-icon:first-child svg {
  margin-top: -14px;
}

.contact-container .contact-info .contact-map {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  height: 450px;
}

@media (max-width: 750px) {
  .contact-container .contact-form-container {
    display: flex;
    flex-direction: column;
    grid-row-gap: 60px;
    row-gap: 60px;
    margin: 2rem 0;
    padding: 0 2rem;
  }
}

@media (max-width: 500px) {
  .contact-container .contact-form-container {
    padding: 0 1rem;
  }
}

.service_detail_container {
  padding: 2rem 0 0 0;
}

.service_detail_container .service_detail_content_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  margin: 2rem 0;
}

.service_detail_container
  .service_detail_content_container
  .service_detail_text_carousel_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 80px;
  row-gap: 80px;
}

.service_detail_container
  .service_detail_content_container
  .service_detail_text_carousel_container
  .service_detail_text
  p {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-align: justify;
  color: #434343;
}

.service_detail_container
  .service_detail_content_container
  .service_detail_text_carousel_container
  .service_detail_text
  p {
  text-align: justify;
}

.service_detail_container
  .service_detail_content_container
  .service_detail_category_container {
  display: flex;
  justify-content: center;
}

/* customer_offer_container_heading */
.customer_offer_container {
  margin-top: 4rem;
}

.customer_offer_container .customer_offer_container_heading h1 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-transform: uppercase;
  color: #0f8015;
}

.customer_offer_container .customer_offer_container_content p {
  padding-top: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #434343;
  text-align: justify;
}

.customer_offer_container .customer_offer_container_content ul {
  margin: 20px 0 0 18px;
}

.customer_offer_container .customer_offer_container_content ul li {
  /* padding-top: 20px; */
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  /* line-height: 10px; */
  color: #434343;
  text-align: justify;
}

@media (max-width: 750px) {
  .service_category {
    min-width: 22rem;
  }

  .service_category_body ul li {
    font-size: 14px;
  }
  .service_detail_container .service_detail_content_container {
    display: flex;
    flex-direction: column;
    grid-row-gap: 60px;
    row-gap: 60px;
    margin: 2rem 0;
  }
}

.service_detail_img_gallery {
  width: 100%;
  background: #f5f5f5;
  padding: 30px 50px;
  position: relative;
}

.image-gallery-image {
  height: 400px;
  object-fit: cover !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 1.5rem !important;
  width: 1.5rem !important;
  border-radius: 50%;
  background: var(--primary-color);
}

.image-gallery-left-nav {
  left: -7.5% !important;
}

.image-gallery-right-nav {
  right: -7.5% !important;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

@media (min-width: 750px) {
  .service_category {
    min-width: 24rem;
  }
}

@media (max-width: 500px) {
  .service_category {
    min-width: 18rem;
  }
}

.covid {
  padding: 2rem 1rem;
}

.covid .content .covid__header h1 {
  text-align: center;
}

.covid .content .covid__header h1 span {
  color: var(--primary-color);
}

.covid .content .covid__subtitle {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.covid .content .covid__subtitle p {
  font-size: 1rem;
  line-height: 2rem;
  text-align: justify;
}


.login_container > h2{
  text-transform: uppercase;
}

.logo{
  width: 100px;
}

.Form_Container{
  margin: 2rem 1rem;
}

.login-form-forgot {
  float: right;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}

.login_container{
    padding: 0 0 0 2rem  ;
}

.login_container h2{
    color: var(--primary-color);
    margin-bottom: 2rem !important;
    text-transform: uppercase;
}
.welcome_container {
  background: var(--primary-color);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.welcome_container h1 {
  font-style: normal;
  font-weight: bold;
  line-height: 77px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
}

.welcome_container p {
  font-style: normal;
  font-weight: normal;
  line-height: 30px;
}

.welcome_container > .welcome_content {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.welcome_container > .welcome_content1 {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.welcome_image{
  margin-left: -10px;
}

.signup_container{
    padding: 0 0 0 2rem  ;
}

.signup_container h2{
    color: var(--primary-color);
    margin-bottom: 2rem !important;
}
/* .ant-form .ant-form-item {
    flex-wrap: wrap;
} */
.forgotPassword .forgotPassword__header {
  border-bottom: 2px solid var(--light-color);
  padding: 0.5rem 2rem;
}

.forgotPassword .forgotPassword__header img {
  width: 90px;
}

.forgotPassword .forgotPassword__container {
  width: 90%;
  margin: 0 auto;
}

.forgotPassword .forgotPassword__body {
  max-width: 450px;
  margin: 0 auto;
  margin-top: 2.5rem;
  text-align: center;
  border: 1px solid var(--light-color);
  padding: 2rem;
  padding-top: 3rem;
  border-radius: 30px;
}

.forgotPassword .forgotPassword__body svg {
  color: #00bc12;
  font-size: 5rem;
}

.forgotPassword .forgotPassword__body h2 {
  padding: 2rem 0 1rem 0;
}

.forgotPassword .forgotPassword__body p {
  padding-bottom: 1rem;
}

.App.mob-menu-open {
  overflow: hidden;
}

.App.mob-menu-open::after {
  background: rgba(0, 0, 0, 0.73);
  width: 100%;
  height: 100%;
  z-index: 1000;
  content: " ";
  position: fixed;
  display: block;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

:root {
  --primary-color: #0f8015;
  --secondary-color: #74bc7c;
  --dark-color: #2d2d2d;
  --light-color: #d8d8d8;
  --bg-color: #2d2d2d;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 !important;
}

a {
  text-decoration: none !important;
  color: #0f8015 !important;
  color: var(--primary-color) !important;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

button {
  border-radius: 5px !important;
}

button.bg-dark {
  color: #ffffff !important;
  background-color: #0f8015 !important;
  background-color: var(--primary-color) !important;
  border: none;
}

button.bg-dark:disabled {
  pointer-events: none;
  background-color: #74bc7c !important;
  background-color: var(--secondary-color) !important;
}

button.bg-dark:hover {
  color: #0f8015 !important;
  color: var(--primary-color) !important;
  background-color: #ffffff !important;
  border: 1px solid #0f8015 !important;
  border: 1px solid var(--primary-color) !important;
}

button.bg-light {
  color: #0f8015 !important;
  color: var(--primary-color) !important;
  background-color: #ffffff !important;
  border: 1px solid #0f8015;
  border: 1px solid var(--primary-color);
}

button.bg-light:hover {
  color: #ffffff !important;
  background-color: #0f8015 !important;
  background-color: var(--primary-color) !important;
  border: none;
}

button .ant-spin {
  margin-left: 1rem;
}

button .ant-spin-dot-item {
  background-color: #ffffff;
}

button:hover .ant-spin-dot-item {
  background-color: #0f8015;
  background-color: var(--primary-color);
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 2px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #4d70c7e7;
}

.ant-popover .ant-popover-buttons .ant-btn.ant-btn-primary {
  background-color: #ff4d4f;
  border: #ff4d4f;
}

.ant-radio .ant-radio-inner {
  border-radius: 0;
  border: 1px solid #0f8015;
  border: 1px solid var(--primary-color);
  height: 1.2rem;
  width: 1.2rem;
  position: relative;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #0f8015;
  background-color: var(--primary-color);
  border-radius: 0;
  height: 0.8rem;
  width: 0.8rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

