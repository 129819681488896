.avatarProf {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatarProf button {
  margin-top: 1.5rem;
  width: 13rem;
  height: 2.3rem;
}
