.radioDropOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
  line-height: 1.4rem;
}

.radioDropOption > p {
  width: 55%;
}

.radioDropOption .radioDropOption__count {
  display: flex;
}

.radioDropOption .radioDropOption__count .radioDropOption__btn {
  min-height: 50px;
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radioDropOption .radioDropOption__count > span {
  font-weight: 700;
  width: 20px;
  margin: auto 0rem;
  text-align: center;
}
