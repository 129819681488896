.dashServicesItem {
    background: #ffffff;
    /* border: 1px solid #cfcfcf; */
    border-radius: 5px;
    padding: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    height: 100%;
  }
  
  .dashServicesItem:hover {
    background-color: var(--primary-color);
    color: #ffffff;
    transition: 0.2s all;
  }
  
  @media screen and (max-width: 290px) {
    .dashServicesItem {
      padding: 1rem;
    }
  }