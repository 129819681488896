.careers-container {
  padding: 2rem 0;
}

.careers-container .careers-info {
  text-align: center;
}

.careers-container .careers-info .careers-info-header span {
  color: var(--primary-color);
}

.careers-container .careers-info .careers-info-description {
  margin-top: 1rem;
  font-size: 1rem;
  text-align: justify;
}

.careers-container .vacancies-container {
  margin: 2rem 0;
}

.careers-container .vacancies-container .vacancies-container-header {
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  margin: 1rem 0 !important;
}
