.notifications li {
  list-style: none;
  border-bottom: 1px solid var(--light-color);
}
.notification_showmore_button {
  margin-top: 3%;
  margin-left: 43%;
  background-color: green;
  color: white;
  border: none;
  border-radius: 0.5rem;
  width: 100px;
  height: 30px;
}
