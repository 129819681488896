.Card_container{
    background: #fff;
    position: relative;
    border-radius: 8px;
    margin-bottom: 30px;
    box-shadow: 0 3px 8px 0 silver;
    
    height: 320px;
    margin-top: 2rem;
}

.Card_container .EOL_Card_Header {
  background-image: url(https://files.dxr.cloud/Dv539m7Xds3onFCkoJperelz413FgrclEmjza1Gc9yPIaba8TU8CTFmCuUzK);
  height: 40%;
  z-index: 1;
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 0;
  top: 0;
  left: 50%;
  -webkit-mask-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#000),
    to(rgba(0, 0, 0, 0))
  );
  /* border-bottom: 1px solid black; */
}
.Card_container h2 {
    position: absolute;
    color: var(--primary-color);
    left: 30px;
    top: 20px;
    text-transform: uppercase;
}

hr{
    position: absolute;
    top: 40%;
}

.Card_container .EOL_Card_content{
    height: 60%;
    position: absolute;
    top:50%;
    padding: 20px 30px;

}
