.about-us-intro-container {
  display: flex;
  padding: 2rem 1rem;
}

.about-us-intro-container > div {
  flex-basis: 50%;
}

.about-us-intro-container .intro-image-container img {
  width: 100%;
}

.about-us-intro-container .intro-text-container .pre-header {
  font-size: 14px;
  color: var(--primary-color);
  font-weight: 600;
}

.about-us-intro-container .intro-text-container .header {
  font-size: 1.5rem;
}

.about-us-intro-container .intro-text-container .intro-description {
  padding: 1rem 0;
  color: #434343;
  font-size: 1rem;
  line-height: 2rem;
  text-align: justify;
}

@media (max-width: 800px) {
  .about-us-intro-container {
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
  }

  .about-us-intro-container .intro-image-container {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
}

@media (max-width: 400px) {
  .about-us-intro-container .intro-image-container {
    width: 100%;
    /* margin: 0 auto; */
    margin-bottom: 1rem;
  }
}
