.termsAndConditions .termsAndConditions__body {
  background-color: #ffffff;
  padding: 1rem 1.4rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  border-radius: 5px;
}

.termsAndConditions .termsAndConditions__body h3 {
  text-transform: uppercase;
  color: var(--primary-color);
}

.termsAndConditions .termsAndConditions__body p {
  line-height: 2rem;
  text-align: justify;
  padding-top: 0.4rem;
  padding-bottom: 1.4rem;
  font-size: 0.9rem;
}
