.navbar-container .top-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 2rem;
}

.navbar-container .top-navbar .navbar-logo .logo {
  width: 100px;
}

.navbar-container .top-navbar .top-header-info {
  display: flex;
}

.navbar-container .top-navbar .top-header-info-item {
  display: flex;
  align-items: center;
  padding: 0 2rem;
}
.navbar-container .top-navbar .top-header-info-item:not(:last-child) {
  border-right: 1px solid var(--light-color);
}

.navbar-container .top-navbar .top-header-info-item .top-header-info-item-icon {
  padding: 5px;
  border: 1px dashed var(--light-color);
}

.navbar-container .top-navbar .top-header-info-item .social svg {
  width: 32px;
  height: 32px;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid var(--light-color);
  color: #4267b2;
}

.navbar-container
  .top-navbar
  .top-header-info-item
  .top-header-info-item-icon
  svg {
  font-size: 1.25rem;
  color: var(--light-color);
}

.navbar-container
  .top-navbar
  .top-header-info-item
  .top-header-info-item-detail {
  margin-left: 10px;
  font-weight: 500;
}

.navbar-container
  .top-navbar
  .top-header-info-item
  .top-header-info-item-detail
  p {
  font-size: 1.15rem;
}

.navbar-container .navbar-menu {
  width: 100%;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-container .navbar-menu .navbar-menu-toggle {
  display: none;
}

.navbar-container .navbar-menu .menu-list {
  list-style: none;
  display: flex;
  margin: 0;
}

.navbar-container .navbar-menu .menu-list .menu-item.has-sub-menu {
  position: relative;
}

.navbar-container .navbar-menu .menu-list .menu-item > a {
  padding: 6px 2rem;
  color: #ffffff !important;
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: center;
}

.navbar-container .navbar-menu .menu-list .menu-item .activeNavLink {
  font-weight: 600;
}

.navbar-container .navbar-menu .menu-list .menu-item:not(:last-child) > a {
  border-right: 2px solid var(--dark-color);
}

.navbar-container a {
  position: relative;
}

.navbar-container .activeNavLink .top-bar,
.navbar-container a:hover .top-bar {
  position: absolute;
  left: 0;
  right: 0;

  margin-left: auto;
  margin-right: auto;
  top: -8px;
  width: 0;
  height: 0;
  border-left: 8px solid rgba(255, 255, 255, 0);
  border-right: 8px solid rgba(255, 255, 255, 0);

  border-top: 8px solid rgb(255, 255, 255);
  transition: 0.1s;
}

.navbar-container .navbar-menu .menu-item .sub-menu {
  display: none;
}

.navbar-container .navbar-menu .menu-item:hover .sub-menu {
  display: block;
  position: absolute;
  top: 2.4rem;
  /* width: 250px; */
  z-index: 99;
}

.navbar-menu .menu-item .sub-menu .sub-menu-list {
  margin-top: 8px;
  list-style: none;
  background-color: var(--primary-color);
  color: #ffffff;
  padding: 10px 1rem;
  line-height: 1.5rem;
  /* column-count: 2; */
  /* column-gap: 20px; */
}

.navbar-menu .menu-item .sub-menu .sub-menu-list li {
  width: 250px;
}

.navbar-menu .sub-menu .sub-menu-list .sub-menu-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: #ffffff !important;
}

.navbar-menu .sub-menu .sub-menu-list .sub-menu-item svg {
  margin-right: 10px;
  font-size: 1rem;
}

.navbar-container .navbar-menu .request-quote-block {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4rem;
}

.navbar-container .navbar-menu .request-quote-block img {
  margin-top: -8px;
  width: 180px;
}

.navbar-container .navbar-menu .request-quote-block a {
  position: absolute;
  color: #ffffff !important;
  font-size: 1rem;
  font-weight: 600;
  margin-top: -22px;
}

.mob-menu {
  position: fixed;
  background: #f9f9f9;
  top: 0;
  left: -270px;
  width: 270px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 99;
  transition: all 0.2s;
}

.mob-menu.visible {
  left: 0;
}

.mob-menu .mob-menu-close {
  position: absolute;
  right: 0;
  margin: 1rem;
  font-size: 2rem;
}

.mob-menu .menu-list {
  list-style: none;
  padding-left: 0;
  margin-top: 3rem;
}

.mob-menu .menu-list .menu-item {
  border-bottom: 1px solid #eeeeee;
}

.mob-menu .menu-list .menu-item a {
  display: block;
  padding: 5px 14px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 30px;
  min-height: 32px;
}

.mob-menu .menu-list .menu-item.has-sub-menu a {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mob-menu .menu-list .mob-menu-dropdown {
  font-size: 1.5rem;
}

.mob-menu .menu-list .menu-item .sub-menu {
  display: none;
}

.mob-menu .menu-list .menu-item .sub-menu.visible {
  display: block;
}

.mob-menu .menu-list .menu-item .sub-menu .sub-menu-list {
  list-style: none;
  padding-left: 10px;
}

.mob-menu .menu-list .menu-item .sub-menu .sub-menu-list .sub-menu-item {
  color: var(--primary-color);
  border-bottom: 1px solid #eeeeee;
  display: block;
  padding: 10px 14px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 30px;
  min-height: 32px;
  display: flex;
  align-items: center;
}

.mob-menu
  .menu-list
  .menu-item
  .sub-menu
  .sub-menu-list
  .sub-menu-item:first-child {
  border-top: 1px solid #eeeeee;
}

.mob-menu .menu-list .menu-item .sub-menu .sub-menu-list .sub-menu-item svg {
  margin-right: 10px;
}

@media (max-width: 1180px) {
  .navbar-container .navbar-menu .navbar-menu-toggle {
    display: block;
    margin-left: 2rem;
  }

  .navbar-container .navbar-menu .navbar-menu-toggle svg {
    font-size: 2rem;
  }

  .navbar-container .navbar-menu .menu-list {
    display: none;
  }

  .navbar-container .navbar-menu .request-quote-block img {
    margin-top: -9px;
    width: 200px;
  }
}

@media (max-width: 820px) {
  .navbar-container .top-navbar {
    display: flex;
    align-items: center;
    margin: 0.5rem 1rem 1rem 1rem;
  }

  .navbar-container .top-navbar .top-header-info {
    display: flex;
    align-items: center;
  }

  .navbar-container .top-navbar .top-header-info-item .social {
    margin-top: 6px;
  }

  .navbar-container
    .top-navbar
    .top-header-info-item
    .top-header-info-item-detail {
    display: none;
  }

  .navbar-container .top-navbar .top-header-info-item {
    padding: 0 1rem 0 0;
  }

  .navbar-container
    .top-navbar
    .top-header-info-item
    .top-header-info-item-icon {
    padding: 5px;
  }

  .navbar-container .top-navbar .top-header-info-item:not(:last-child) {
    border: none;
  }
}

@media (max-width: 465px) {
  .navbar-container .top-navbar .top-header-info {
    display: flex;
    flex-direction: column;
  }

  .navbar-container
    .top-navbar
    .top-header-info-item
    .top-header-info-item-icon {
    padding: 5px;
  }

  .navbar-container .top-navbar .top-header-info-item .social svg {
    padding: 10px;
    border-radius: 50%;
  }

  .navbar-container
    .top-navbar
    .top-header-info-item
    .top-header-info-item-icon
    svg {
    font-size: 1rem;
  }

  .navbar-container
    .top-navbar
    .top-header-info-item
    .top-header-info-item-detail {
    font-size: 1rem;
  }

  .navbar-container .top-navbar .top-header-info-item .social svg {
    padding: 5px;
  }

  .navbar-container .navbar-menu .navbar-menu-toggle {
    margin-left: 1rem;
  }

  .navbar-container .navbar-menu .request-quote-block {
    margin-right: 1rem;
  }
}
