.documentreport .documentreport__body {
  background-color: #ffffff;
  border-radius: 5px;
}

.documentreport .documentreport__body .documentreport__bodyHead {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid var(--light-color);
  padding: 1rem;
  font-weight: 500;
}

.documentreport .documentreport__body .documentreport__bodyHead p:first-child {
  width: 45%;
}
.documentreport .documentreport__body .documentreport__bodyHead p:nth-child(2) {
  width: 25%;
}
.documentreport .documentreport__body .documentreport__bodyHead p:nth-child(3) {
  width: 30%;
  text-align: end;
}

.documentreport .documentreport__body ul li {
  list-style: none;
  border-bottom: 1px solid var(--light-color);
}

.documentreport .documentreport__body ul li:last-child {
  border-bottom: none;
}

.documentreport .documentreport__footer {
  display: flex;
  align-items: center;
  margin-top: 1.4rem;
}

.documentreport .documentreport__footer > p {
  padding-left: 0.4rem;
  font-size: 0.8rem;
}
.document_showmore_button{
  margin-left: 43%;
  background-color: green;
  color: white;
  border: none;
  border-radius: 0.5rem;
  width: 100px;
  height: 30px;
}

@media screen and (max-width: 620px) {
  .documentreport .documentreport__body .documentreport__bodyHead {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.9rem;
  }
}
