.serviceListItem {
  display: flex;
  align-items: center;
  height: 3.8rem;
}

.serviceListItem .serviceListItem__icon {
  margin-right: 1rem;
  border: 2px solid var(--primary-color);
  height: 32px;
  min-width: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: var(--primary-color);
}

.serviceListItem .serviceListItem__header {
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: 500;
}

@media (max-width: 750px) {
  .serviceListItem {
    height: 3.2rem;
  }

  .serviceListItem .serviceListItem__header {
    font-size: 0.9rem;
    line-height: 1.4rem;
  }
}
