/*  */

.service_category {
  min-width: 24rem;
}

.service_category_header {
  text-align: center;
  background: #0f8015;
  padding: 10px 0;
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.1), 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.service_category_header h1 {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #ffffff;
}

.service_category_body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.1), 2px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
}

.service_category_body ul li {
  list-style-type: none;
  font-size: 1rem;
  line-height: 25px;
  text-align: justify;
  color: #434343;
  padding: 5px 0;
  cursor: pointer;
}



.service_category_body ul li a {
  color: #434343 ;
}

.service_category_body ul li a:hover {
  color: #0f8015 !important;
}

@media (max-width: 750px) {
  .service_category {
    min-width: 22rem;
  }

  .service_category_body ul li {
    font-size: 14px;
  }
}

@media (min-width: 750px) {
  .service_category {
    min-width: 22rem;
  }
}

@media (max-width: 500px) {
  .service_category {
    min-width: 18rem;
  }
}
