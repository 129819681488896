.stats-container {
  background: url("../../../assets/about-us-stats.png") no-repeat center;
  width: 100%;
  padding: 2rem 1rem;
  background-size: cover;
  color: #ffffff;
}

.stats-container .info {
  font-size: 1rem;
  line-height: 2rem;
  text-align: center;
}

.stats-container .stats-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 2rem;
  margin: 1rem 0;
}

.stats-container .stats-group .stats-item {
  text-align: center;
  min-width: 220px;
}

.stats-container .stats-group .stats-item .stats-number {
  font-size: 2rem;
  font-weight: 500;
  color: #00bc12;
}

.stats-container .stats-group .stats-item .stats-title {
  font-size: 1rem;
  text-transform: uppercase;
}
