.contactUs .contactUs__body {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 5px;
}

.contactUs .contactUs__body .contactUs__details {
  margin-bottom: 2rem;
}

.contactUs .contactUs__body .contactUs__details .contactUs__detail {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.contactUs .contactUs__body .contactUs__details .contactUs__detail svg {
  margin-right: 0.5rem;
}
