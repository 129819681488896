.profile .profile__container {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 1.5rem 2rem;
  border-radius: 5px;
}

.profile .profile__container .profile__avatar {
  flex: 0.3;
  padding-top: 1rem;
}

.profile .profile__container .profile__userForm {
  flex: 0.65;
}

.profile .profile__handles {
  margin-top: 0.4rem;
  text-align: right;
}

.profile .profile__handles .profile__btns {
  margin-top: 1rem;
  width: 100%;
}

.profile .profile__handles .profile__btns button {
  width: 9rem;
  height: 2.3rem;
}

.profile .profile__handles .profile__btns button:last-child {
  margin-left: 0.8rem;
}

@media screen and (max-width: 420px) {
  .profile .profile__handles .profile__btns {
    display: flex;
    flex-direction: column-reverse;
  }

  .profile .profile__handles .profile__btns button {
    width: 100%;
  }

  .profile .profile__handles .profile__btns button:last-child {
    margin-left: 0rem;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 680px) {
  .profile .profile__container {
    flex-direction: column;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }

  .profile .profile__container .profile__avatar {
    margin-bottom: 2rem;
  }
}
