.record {
  display: flex;
  align-items: center;
  padding: 1rem;
  font-size: 0.9rem;
}

.record .record__name {
  width: 45%;
}

.record .record__date {
  width: 25%;
}

.record .record__options {
  display: flex;
  justify-content: flex-end;
  width: 30%;
}

.record .record__options .record__btn button {
  width: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.record .record__options .record__btn:first-child {
  margin-right: 0.4rem;
}

@media screen and (max-width: 620px) {
  .record {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 0.8rem;
  }
}
